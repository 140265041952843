.simple-query-charts {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px 20px;
}

.simple-query-chart {
    min-width: 300px;
    height: 140px;
    margin: 0 20px 20px 0;
    padding: 10px;
    position: relative;

    .chart {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        padding-top: 10px;
    }

    .chart-area {
      fill: rgba($blue, 0.05);
    }

    .chart-line {
      stroke: rgba($blue, 0.15);
    }

    .stats {
        padding: 10px;
    }

    .stats-text {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
    }

    .stats-title {
        font-size: 12px;
        color: $mid-grey;
        line-height: 1.3;
        text-transform: uppercase;
        font-weight: 800;
    }

    .stats-value {
        font-weight: 300;
        font-size: 36px;
        margin-bottom: 10px;
        display: inline-block;
    }

    .stats-growth {
        font-size: 20px;
        font-weight: bold;
        color: $green;
        margin-left: 10px;
    }

    .growth-icon {
        font-size: 24px;
        position: relative;
        top: 5px;
        line-height: 0;

        &:before {
            line-height: 0;
        }
    }
}
