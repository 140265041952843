.smart-group-filter-items {
    position: absolute;
    left: 0; top: 0; bottom: 0; right: 0;

    $header-height: 100px;
    $footer-height: 0px;

    $sidebar-width: 200px;

    .property-filters {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: $lighter-grey;
        white-space: nowrap;

        .item-properties {
            position: absolute;
            top: 0;
            bottom: 0;
            width: $sidebar-width;
            left: 0;
            background: $lighter-grey;
            padding: 10px;
            overflow-y: scroll;

            button {
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                line-height: 25px;
                padding: 3px 0;
                margin: 0 0 15px 0;
                text-align: center;
                transition: all 0.1s ease-in-out;

                outline: none;
                border: $border;
                box-shadow: 0px 1px 0px 0px $border-color;
                border-radius: 3px;
                background: $white;
                border-radius: 3px;
                color: $dark-grey;
                font-size: 10px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: 500;

                word-wrap: break-word;
                white-space: normal;
                line-height: normal;

                &:hover {
                  cursor: pointer;
                  background: rgba($lighter-grey, 0.5);
                }

                &:active {
                    box-shadow: none;
                    top: 1px;
                }
            }
        }


        .item-property-filter {
            width: 230px;
            height: 100%;
            position: relative;
            display: inline-block;
            border-right: $border;

            p.icon-search {
                position: absolute;
                top: 68px;
                left: 10px;
                color: $mid-grey;
            }
        }

        .item-property-filters {
            position: absolute;
            left: $sidebar-width; top: 0; right: 0; bottom: 0;
            overflow: auto;
            border-left: $border;

            header {
                height: $header-height;
                border-bottom: 2px solid $border-color;
                padding: 15px 13px;

                h1 {
                    display: inline-block;
                    position: relative;
                    text-transform: uppercase;
                    font-size: 10px;
                    top: 2px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    color: $dark-grey;
                }

                .remove-item {
                    float: right;
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    padding: 13px;
                    margin: -13px -13px 0 0;
                    font-size: 24px;
                    color: $dark-grey;

                    &:hover {
                        color: $blue;
                    }
                }

                input {
                    position: absolute;
                    display: block;
                    width: 100%;
                    font-size: 12px;
                    padding: 12px 10px 12px 39px;
                    border: none;
                    border-top: $border;
                    border-radius: none;
                    top: 53px;
                    left: 0px;

                    &:disabled {
                        opacity: 0.5;
                        background: $lighter-grey;
                    }
                }
            }

            main {
                position: absolute;
                top: $header-height;
                left: 0;
                bottom: 0;
                right: 0;
                overflow-y: auto;
                overflow-x: hidden;
            }

            li {
                font-size: 14px;
                border-bottom: $border;
                background: $white;
                width: 100%;
                white-space: normal;

                &.unavailable {
                    color: rgba($mid-grey, 0.5);
                    border: none;
                    background: $lighter-grey;
                }

                &:not(.unavailable):hover {
                    color: $blue;
                    background: $lighter-grey;
                }

                &:not(.unavailable) label {
                    cursor: pointer;
                }

                // &.selected {
                //     background: $blue;
                //     color: $background-color-normal;
                // }

                label {
                    display: block;
                    padding: 1em 0.5em;
                    position: relative;

                    input[type=checkbox] {
                        position: relative;
                        margin: 0 10px 0 5px;
                        display: inline-block;
                        pointer-events: none;
                    }

                    .item-property-value-id {
                        font-size: 10px;
                        width: 230px;
                        overflow: hidden;
                        word-wrap: break-word;
                        width: 150px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .item-property-value-count {
                        position: absolute;
                        top: 4px; right: 4px;
                        // top: 4px;
                        // right: 4px;
                        // float: right;
                        font-size: 0.8em;
                        letter-spacing: 1px;
                        opacity: 0.7;
                    }
                }
                &.selected {
                    .item-property-value-id {
                        font-weight: 600;
                        color: $blue;
                    }
                }
                .ui-match {
                    background: rgba($blue, 0.3);
                }
            }

            footer {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: $footer-height;
                background-color: $white;
                line-height: $footer-height;
                border-top: $border;
                padding: 0 20px;
                color: #aaa;
                display: none;

                &:hover {
                    color: #888;
                }

                .property-details {
                    font-size: 10px;
                    float: right;
                    line-height: $footer-height;
                }
            }
        }
    }
}


.smart-group-filter-customers,
.smart-group-filter-location {
    h1 {
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 400px;
        font-size: 17px;
        color: $mid-grey;
        font-weight: 600;
    }
}


.smart-group-filter-stores-without-comp-modifier {
    position: absolute;
    left: 0; top: 0; bottom: 0; right: 0;
    padding: 20px;
    overflow: auto;
    background: $lighter-grey;

    .store {
        padding: 15px 20px;
        margin: 0 20px 20px 0;
        display: inline-block;
        width: 350px;
        line-height: 1.3;
        color: $dark-grey;
        float: left;
        min-height: 120px;

        &:not(.selected):hover {
            background: rgba($lighter-grey, 0.5);
        }

        &:hover {
            cursor: pointer;
        }

        &.selected {
            background: $lighter-grey;
            border-color: $blue;
            & > * {
                color: $blue !important;
            }
        }

        header {
            clear: both;
            margin-bottom: 8px;
        }

        .store-name {
            font-weight: 600;
            font-size: 16px;
            display: inline-block;
            text-transform: capitalize;
        }

        .store-type {
            font-size: 12px;
            float: right;
            position: relative;
            top: 3px;
            font-style: italic;
            opacity: 0.5;
        }

        .store-info {
            min-height: 60px;
            font-size: 12px;
            opacity: 0.6;
        }

        .store-address {
            text-transform: capitalize;
            // font-style: italic;
            line-height: 1.5;

            .store-address-country {
                margin-bottom: 5px;
                font-weight: 600;
                font-size: 13px;
            }

            .store-address-street,
            .store-address-region,
            .store-address-city {
                // font-style: italic;
            }

            .store-address-city {
                display: inline-block;
                &:after {
                    content:',';
                }
            }

            .store-address-region {
                text-transform: uppercase;
                display: inline-block;
            }
        }
    }
}


.smart-group-filter-stores-with-comp-modifier {
    position: absolute;
    left: 0; top: 0; bottom: 0; right: 0;
    padding: 20px;
    padding-top: 40px;
    overflow: auto;
    background: $lighter-grey;
    user-select: none;

    & > header {
        position: absolute;
        top: 0; left: 0; right: 0;
        height: 50px;
        line-height: 50px;
        background: $white;
        border-bottom: $border;
        padding: 0 20px;

        label {
            font-size: 12px;
            display: inline-block;
            color: $mid-grey;
            cursor: pointer;
            &:hover {
                color: $dark-grey;
                input {
                    opacity: 1.0;
                }
            }
            input {
                position: relative;
                top: -1px;
                margin-right: 7px;
                opacity: 0.7;
            }
        }

        button {
            line-height: 50px;
        }
    }

    .stores-comp {
        position: absolute;
        top: 50px; left: 0; bottom: 0;
        width: calc(50%);
        border-right: $border;
    }

    .stores-non-comp {
        position: absolute;
        top: 50px; right: 0; bottom: 0;
        width: calc(50%);

        &.disabled {
            opacity: 0.5;
            pointer-events: none;

            .store.selected {
                background: $white;
                border-color: $border-color;
                & > * {
                    color: $mid-grey !important;
                }
            }
        }
    }

    button {
        background: none;
        box-shadow: none;
        font-size: 12px;
        display: inline-block;
        margin: 0 10px;
        border: none;
        float: right;
        line-height: 30px;
        height: auto;
        text-decoration: underline;
        color: $mid-grey;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: none;
            color: $blue;
            box-shadow: none;
        }
    }

    .stores-comp, .stores-non-comp { & > header {
        padding: 20px;
        line-height: 25px;
        background: $white;
        height: 65px;

        h1 {
            display: inline-block;
            font-size: 12px;
            margin-right: 5px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
            color: $mid-grey;
        }
    }}

    .stores {
        position: absolute;
        bottom: 0; left: 0; right: 0;
        top: 65px;
        padding: 20px;
        padding-bottom: 0;
        overflow: scroll;
        overflow-x: hidden;
        border-top: $border;
    }

    .store {
        padding: 15px 20px;
        margin: 0 20px 20px 0;
        display: inline-block;
        line-height: 1.3;
        color: $dark-grey;
        width: 100%;

        &:not(.selected):hover {
            background: rgba($lighter-grey, 0.5);
        }

        &:hover {
            cursor: pointer;
        }

        &.selected {
            background: $white;
            border-color: $blue;
            & > * {
                color: $blue !important;
            }
        }

        .store-name {
            font-weight: 600;
            font-size: 16px;
            display: inline-block;
            text-transform: capitalize;
        }

        .store-type {
            font-size: 12px;
            float: right;
            position: relative;
            top: 3px;
            font-style: italic;
            opacity: 0.5;
        }
    }
}


.smart-group-filter-customers {
    position: absolute;
    left: 0; top: 0; bottom: 0; right: 0;
    padding: 20px;

    overflow: auto;
    background: $lighter-grey;

    .customer-group {
        transition: all 0.1s ease-in-out;
        padding: 15px 20px;
        margin: 0 20px 20px 0;
        float: left;

        width: 350px;
        line-height: 1.3;
        color: $mid-grey;
        min-height: 110px;

        & > * {
            transition: all 0.1s ease-in-out;
        }


        &:not(.selected):hover {
            background: rgba($lighter-grey, 0.5);
        }

        &:hover {
            cursor: pointer;
        }

        &.selected {
            background: $lighter-grey;
            border-color: $blue;

            & > * {
                color: $blue !important;
            }
        }

        .group-name {
            font-weight: 600;
            font-size: 16px;
            display: inline-block;
            text-transform: capitalize;
            line-height: 30px;
            color: $dark-grey;
        }
    }

    .coming-soon {
        color: $mid-grey;
        font-size: 20px;
        font-style: italic;
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
}



.smart-groups-filter-time {

    & > button {
        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;
        width: 100%;
        border: none;
        border-left: $border;
        border-bottom: $border;
        text-align: center;
        color: rgba($navy, 0.75);
        transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);

        .filter-display-date-container,
        span {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            background-color: $lighter-grey;
            border: $border;
            border-color: rgba($border-color, 0.6);
            border-radius: 4px;
        }

        .filter-display-date-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 184px;

            .filter-display-date-description {
                display: flex;
            }

            .filter-display-separator {
                margin: 0 4px;
            }
        }

        i {
            width: 16px;
            text-align: center;
            color: $dark-grey;
            position: relative;
            top: 0px;
            margin-right: 3px;
        }

        &:hover,
        &.selected {
            background: $white;

            .filter-display-date-container,
            span {
                background-color: #f3f3f5;
            }
        }

        &.selected {
            .filter-display-date-container,
            span {
                background-color: $blue;
                border-color: $blue;
                color: $white;
            }
            i {
                color: $white;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:active {
            box-shadow: inset 0 1px 1px rgba($navy, 0.1);
        }

        @media (max-width: $navbar-collapse-breakpoint) {
            &.selected {
                .filter-display-date-container, span, i {
                    color: inherit;
                }
            }
        }
    }

    .date-select-container {
        position: absolute;
        top: 48px;
        right: 8px;
        z-index: $z-dropdown;

        &:after {
            content: " ";
            position: absolute;
            right: 120px;
            top: -10px;
            display: block;
            width: 20px;
            height: 20px;
            background: $white;
            box-shadow: 3px 3px 6px rgba(black, 0.1);
            border: $border;
            transform: rotate(45deg);
            z-index: $z-cloak;
        }

        @media (max-width: $navbar-collapse-breakpoint) {
            &:after {
                right: 65px;
            }
        }
    }
}
