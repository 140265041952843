@import 'mapboxGL';

.mapboxgl-popup {
    min-width: 300px;
    min-height: 50px;
}

.mapboxgl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 10px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    pointer-events: auto;
    font-family: 'Open Sans', sans-serif;

    .marker-popup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 4px;
        }

        .address {
            display: flex;
            font-size: 10px;

            .address-city {
                text-transform: capitalize;
            }

            .address-country {
                text-transform: capitalize;
            }

            .address-region {
                text-transform: uppercase;
            }

            .address-address-postal-code {
                text-transform: capitalize;
            }
        }
    }

    button.mapboxgl-popup-close-button {
        height: 18px;
        width: 18px;
        background: white;
        z-index: 1;
        border-radius: 10px;
        line-height: 0;
        top: -5px;
        right: -5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }
}