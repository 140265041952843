
.customer-stats {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    display: table;
    padding: 10px 0;
    min-height: 75px;

    li {
        width: 26.666666667%;
        float: left;
        text-align: center;
        border-right: $border;
        padding: 5px 30px;
        height: 100%;

        &:first-child {
            width: 20%;
        }

        .value {
            font-weight: 300;
            font-size: 24px;
            line-height: 1.4;
            // margin-bottom: 3px;
            // clear: both;
            display: block;
        }

        .title {
            font-size: 11px;
            display: block;
            color: $mid-grey;
            line-height: 1.4;
            text-transform: uppercase;
            font-weight: 400;
            // margin-bottom: 5px;
            // float: left;
        }

        .avg {
            font-size: 11px;
            margin-top: 4px;
            line-height: 1.1;
            font-weight: 400;
            color: $mid-grey;
            // float: right;
            em {
                color: $dark-grey;
                font-weight: 600;
            }
            small {
                text-transform: lowercase;
                // color: $mid-grey;
                margin-left: 0.3em;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 10px 5px;
            height: 100px;

            .title,
            .avg {
                float: none;
            }
        }
    }
}
