$ag-compatibility-mode: 'legacy';

@import '@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss';

.ag-theme-alpine {
    font-family: $font-stack;

    .ag-root-wrapper,
    .ag-root {
        border: none;
        background-color: $body-background-color;

    }

    .ag-cell {
        padding: 2px;
        outline: none;
        border: none;
        border-right: $border;
        color: #555;

        a {
            color: $blue;
            text-decoration: underline;
        }
    }

    .ag-header-cell-resize::after {
        background-color: transparent;
    }

    .ag-cell-focus {
        border: 1px solid $blue !important;
        &.cell-value-group-end {
            text-indent: -1px;
        }
    }
    .ag-cell-no-focus {
        border: 1px solid transparent;
        border-right: $border;
    }
    .ag-popup-backdrop {
        z-index: $z-overlay;
    }
    .ag-header {
        z-index: 3;
        border-bottom: $border;
        background: $white;
        box-shadow: 0px 1px 5px rgba(black, 0.1);
    }
    .ag-header-group-cell {
        font-weight: 600;
    }

    .ag-pinned-left-header {
        z-index: 2;
        position: relative;
        border-right: 1px solid $border-color;
    }

    .ag-center-cols-container .ag-row {
        border: none;
    }

    .ag-floating-top-viewport .ag-floating-top-container {
        border-bottom: 1px solid #e9eaed;
    }

    .ag-pinned-left-cols-container {
        z-index: 1;
        position: relative;
        box-shadow: 1px -1px 5px rgba(black, 0.1);

        .ag-row {
            position: absolute;
            z-index: 1;
            border-bottom: none;

            > * {
                border-left: $border;
            }
        }
    }

    .ag-pinned-left-floating-top {
        position: relative;
        border-bottom: $border;

        .ag-row {
            position: absolute;
            z-index: 2;
        }

        .ag-row:last-child {
            border-bottom: none;
        }
    }


    .ag-pinned-left-cols-viewport {
        position: absolute;
        z-index: 3;
        box-shadow: 1px 3px 5px rgba(black, 0.1);
        border-right: 1px solid darken($border-color, 3%);

        .ag-cell {
            font-weight: 600;
        }
        .ag-cell:last-child {
            border-right: none;
        }
    }

    .ag-center-cols-clipper {
        z-index: 0;
    }

    .ag-header-row:not(:first-child) .ag-header-cell {
        border-top: $border;
    }


    .ag-header-cell,
    .ag-header-group-cell {
        font-size: 10px;
        color: $dark-grey;
        text-transform: uppercase;
    }

    .ag-header-viewport .ag-header-group .ag-header-cell:last-child,
    .cell-value-group-end {
        border-right: 2px solid darken($body-background-color, 4%);
    }

    .ag-header-viewport {
        .ag-header-group-cell {
            border-right: 1px solid darken($body-background-color, 4%);
        }

        & > .ag-header-container .ag-header-cell,
        .ag-header-group .ag-header-cell {
            border-right: $border;
        }
    }
    .ag-pinned-left-header {
        & > .ag-header-group:not(:last-child) .ag-header-cell,
        & > .ag-header-cell:not(:last-child) {
            border-right: $border;
        }
    }
    .ag-header-group-cell-no-group {
        border-bottom: $border;
    }

    .ag-floating-top {
        border: none;
    }

    .ag-body {
        $row-height: 85px;

        .ag-cell {
            color: #555;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .ag-header-group-cell-label {
        display: flex;
        justify-content: center;
        padding: 4px;
        font-weight: bold;
    }

    .ag-header-group-text {
        margin-right: 2px;
    }

    .ag-cell-last-left-pinned {
        font-weight: 600;
    }

    .ag-header-cell {
        padding-left: 8px;
        padding-right: 4px;
        border-top: $border;
    }

    .ag-header-cell-text {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-style: normal;
        color: #555;
        font-weight: 400;
    }

    .ag-header-cell-menu-button {
        color: $mid-grey;
    }

    #overlay {
        background: rgba(white, 0.75);
        pointer-events: auto !important;
        z-index: $z-overlay;
    }

    .ag-overlay-loading-center {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        z-index: 2;
        opacity: 0.8;
        color: transparent;

        &:after {
            width: 100px;
            height: 70px;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 100px;
            z-index: 4;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 50px;
            content: ' ';
        }
    }
    .ag-header-cell-menu-button:hover {
        border: none;
        cursor: pointer;
    }
    .ag-header-icon {
        color: $light-grey;

        .ag-icon-desc::before {
            color: $light-grey;
        }
    }

    .ag-icon.ag-icon-menu {
        font-size: 18px;
        line-height: 18px;
    }

    .ag-row:last-child {
        border-bottom: $border;
    }
    .ag-row-odd {
        background-color: #f6f6f6;
    }
    .ag-row-even {
        background-color: #fff;
    }
    .ag-loading-panel {
        background-color: rgba(255, 255, 255, 0.5);
    }
    .ag-loading-center {
        background-color: #fff;
        border: $border;
        border-radius: 10px;
        padding: 10px;
    }
    .ag-row-selected {
        background-color: #b0e0e6;
    }
    .ag-group-cell-entire-row {
        background-color: #aaa;
        padding: 4px;
    }
    .ag-footer-cell-entire-row {
        background-color: #aaa;
        padding: 4px;
    }
    .ag-group-cell {
        font-style: italic;
    }
    .ag-footer-cell {
        font-style: italic;
    }
    .ag-filter-checkbox {
        position: relative;
        top: 2px;
        left: 2px;
    }
    .ag-filter-header-container {
        border-bottom: 1px solid #d3d3d3;
    }
    .ag-filter {
        border: 1px solid #000;
        background-color: #f0f0f0;
    }
    .ag-selection-checkbox {
        margin-left: 4px;
    }
    .ag-paging-panel {
        padding: 4px;
    }
    .ag-paging-button {
        margin-left: 4px;
        margin-right: 4px;
    }
    .ag-paging-row-summary-panel {
        display: inline-block;
        width: 300px;
    }
    .cell-blank-value {
        color: $light-grey;
    }
    .cell-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
    }
    .ag-filter-item > label {
        display: flex;
        justify-content: center;
    }
    .ag-filter-filter[type='text'] {
        width: 100%;
        border: none;
        margin: 0;
        font-size: 12px;
    }
    .ag-filter-value {
        color: $dark-grey;
        font-size: 13px;
        margin-left: 2px;
    }
    .ag-filter-header-container {
        color: $dark-grey;
        font-size: 13px;
    }
    .ag-filter {
        border: $border;
        background: white;
        box-shadow: 0px 3px 7px rgba(black, 0.08);
    }
}
