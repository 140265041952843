.view-sales {
    position: relative;
    margin: 20px;

    .overall-stats-container {
        height: 100%;
        width: auto;
        position: relative;
    }

    .overall-stats {
        position: relative;
        margin-bottom: 20px;
        padding: 10px 0;
        overflow: hidden;
        display: table;
        width: 100%;
        height: 122px;

        &.loading ul {
            opacity: 0;
        }

        &:not(.loading) ul {
            opacity: 1;
        }

        ul {
            display: grid;
            grid-gap: 0px;
            max-width: 100%;
            transition: opacity 0.3s;
        }

        @media (max-width: 600px) {
            ul {
                grid-template-columns: repeat(2, minmax(100px, 1fr));
            }
            ul li:nth-child(2n+1) {
                border-left: none !important;
            }
        }

        @media (min-width: 601px) and (max-width: 999px) {
            ul {
                grid-template-columns: repeat(3, minmax(100px, 1fr));
            }
            ul li:nth-child(3n+1) {
                border-left: none !important;
            }
        }

        @media(min-width: 1000px) {
            ul {
                grid-template-columns: repeat(4, minmax(100px, 1fr));
            }
            ul li:nth-child(4n+1) {
                border-left: none !important;
            }
        }

        li {
            text-align: center;
            border-left: $border;
            padding: 10px 10px;
            position: relative;

            &.selected {
                .title {
                    color: $light-blue;
                }
            }

            &:hover {
                cursor: zoom-in;

                .value, .growth, .perchevron {
                    color: $blue !important;
                }

                .title {
                    color: $light-blue;
                }
            }

            font-size: 18px;
            @media(min-width: 700px) {
                font-size: 19px;

                .value {
                    font-size: 1em;
                }
            }

            @media(min-width: 1200px) {
                font-size: 25px;
            }

            .title {
                color: $mid-grey;
                text-transform: uppercase;
                max-width: 200px;
                display: inline-block;
                font-size: 0.5em;
                color: $mid-grey;
                line-height: 1.3;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 7px;
            }

            .value {
                font-weight: 300;
                font-size: 1.3em;
                margin-bottom: 9px;

                &.blank {
                    position: relative;
                    color: $light-grey !important;
                }
            }

            .growth {
                font-size: 0.5em;
                margin-bottom: 0px;
                margin-left: 8px;
                min-height: 20px;

                .perchevron {
                    position: relative;
                    left: -0.45em;
                }
            }
        }
    }

    .chart-metrics {
        position: relative;
        width: auto;
        height: 350px;
        margin-bottom: 20px;
    }

    .top-items {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        min-height: 325px;

        &.hidden {
            display: none;
        }

        .top-items-header {
            height: 16px;
            width: 100%;
            display: flex;
            padding: 0 8px;
            justify-content: space-between;
        }

        .header-label, .more-info-label {
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
        }

        .more-info-label {
            justify-content: flex-end;
        }

        a {
            height: 14px;
            border-bottom: 1px dotted $mid-grey;
            color: $dark-grey;
            font-weight: 600;
            font-size: 12px;
            line-height: 1;

            &:hover {
                color: $blue;
            }
        }

        h1 {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            color: $darker-grey;
        }

        .items {
            padding-top: 10px;
            width: 100%;
            display: flex;

            .item-container:last-child .item {
                border-right: none;
            }

            ul {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: center;
                width: 100%;

                &.collapsed {
                    flex-direction: column;
                    align-items: stretch;
                    .item-container {
                        align-self: stretch;
                        max-width: initial;
                    }
                    .item-name {
                        font-size: 16px;
                    }
                    .item {
                        padding: 30px 15px;
                        border-right: none;
                    }
                }
            }

            .item-container {
                user-select: none;
                flex: 1;
                align-self: baseline;
                cursor: default;
                position: relative;
                display: block;
            }

            .item {
                border-right: $border;
                padding: 10px;
                text-align: center;

                .item-name {
                    height: 34px;
                    margin-top: 12px;
                    user-select: text;
                    font-size: 12px;
                    line-height: 1.4;
                    font-style: italic;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .item-image {
                    text-align: center;
                    display: block;
                    border-radius: 10px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 200px;
                    width: 100%;
                }

                .item-metrics {
                    margin-top: 10px;
                    text-align: left;
                    display: flex;
                    flex-direction: column;

                    .item-metric-group {
                        flex: 0 0 auto;
                        border-bottom: $border;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;
                        padding: 4px 3px;
                        margin-bottom: 2px;

                        &:last-child {
                            border-bottom: none;
                        }

                        &:not(.bare) {
                            .item-metric-header-group {
                                display: block;
                                border-color: $border-color;
                            }
                            .item-metric {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-around;
                                padding-left: 4px;
                                .item-metric-header-name {
                                    flex: 1 1 auto;
                                }
                            }
                        }
                        &.bare {
                            flex-direction: row;

                            .item-metric-header-group {
                                flex: 1 1 auto;
                            }
                            .item-metric {
                                flex: 0 0 auto;
                                max-width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .item-metric-header-group {
                            color: $mid-grey;
                        }
                        .item-metric-header-name {
                            color: $mid-grey;
                            max-width: 100%;
                            white-space: pre-wrap;
                            line-height: 1.1;
                            padding-bottom: 1px;
                            &:first-child {
                                padding-top: 1px;
                            }
                        }
                        .item-metric-value {
                            color: $dark-grey;
                            &.blank {
                                color: $mid-grey;
                                font-style: italic;
                            }
                            .perchevron:before {
                                top: 0;
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
