.animation-fade {
    &.ng-enter, &.ng-leave {
        transition: all .33s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        opacity: 0;
        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;
        &.ng-leave-active {
            opacity: 0;
        }
    }
}


.animation-slide-down {
    &.ng-enter, &.ng-leave {
        transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        transform: translate(0, -100%);
        &.ng-enter-active {
            transform: translate(0, 0);
        }
    }

    &.ng-leave {
        transform: translate(0, 0);
        &.ng-leave-active {
            transform: translate(0, -100%);
        }
    }
}

.animation-slide-bottomleft {
    &.ng-enter, &.ng-leave {
        transition: all .22s cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    &.ng-enter {
        opacity: 0;
        transform: translate(0, -10px);
        &.ng-enter-active {
            opacity: 1;
            transform: translate(0px, 0px);
            // transform: translate(0, 0);
        }
    }

    &.ng-leave {
        opacity: 1;
        // transform: translate(0, 0);
        transform: translate(0px, 0px);
        &.ng-leave-active {
            opacity: 0;
            // transform: translate(-10px, 10px);
            transform: translate(0, 10px);
        }
    }
}
