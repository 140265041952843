.view-store-status {

    $side-padding: 20px;
    $top-padding: 20px;
    $header-height: 40px;

    position: absolute;
    top: $top-padding;
    left: $side-padding;
    right: $side-padding;
    bottom: 5px;
    overflow-y: scroll;
    background: $white;
    border: $border;

    .store-status-container {
        width: 100%;
        min-height: 100%;
        border: none;

        & > header {
            position: fixed;
            top: $top-padding; left: $side-padding + 1; right: $side-padding + 1;
            box-shadow: 0px 1px 5px rgba(black, 0.1);
            z-index: $z-elevated + 1;
        }
        & > main {
            margin-top: $header-height;
        }
    }

    .store-status-table {
        font-family: inherit;
        border-collapse: collapse;
        width: 100%;
        border: none;

        td, th {
            width: 50%;
            border: 1px solid #e9eaed;
            padding: 14px;
        }

        tr {
            background-color: $white;
        }

        tbody tr:hover {
            background-color: $lighter-grey;
            color: $blue;
        }

        tr:first-child td {
            border-top: 0;
        }

        tr td:first-child,
        tr th:first-child {
            border-left: 0;
        }

        tr td:last-child,
        tr th:last-child {
            border-right: 0;
        }

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            font-weight: 700;
            color: #555;
            font-size: 12px;
            text-transform: capitalize;
            height: $header-height;
        }
    }
}