* {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

html, body {
    height: 100%;
    overflow: hidden;
    font-family: $font-stack;
    background: $body-background-color;
    color: $body-text-color;
    font-size: 16px;
}


button, input, select, textarea {
    line-height: 1.5;
    font-family: $font-stack;
    color: $dark-grey;
    outline: none;
    @include placeholder {
      color: rgba($mid-grey, 0.7);
    }
}


select {
    &:hover {
        cursor: pointer;
    }
}


button {
    background: $white;
    border: none;
    color: #555;
    text-align: center;
    line-height: 40px;
    height: 40px;
    display: inline-block;
    border: $border;
    font-size: 14px;
    padding: 0;
    margin: 0;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:active:not([disabled]) {
        box-shadow: inset 0px 1px 2px rgba(black, 0.1);
    }

    &[disabled] {
        background: none;
        color: #ccc;
        cursor: default;
    }

    &:not([disabled]):hover {
        cursor: pointer;
        background: $lighter-grey;
    }

    i {
        display: inline-block;
        width: 40px;
        position: relative;
        top: 1px;
    }
}

input[type=text] {
    appearance: none;
    border: none;
    font-size: 14px;
    padding: 12px 10px;

    &:disabled {
        opacity: 0.5;
        background: $lighter-grey;
    }
}

input, select {
    font-family: $font-stack;
    color: $dark-grey;
    outline: none;
}

fieldset {
    border: none;
}

a {
    color: inherit;
    text-decoration: none;
}

em {
    font-weight: 600;
}

label {
    display: block;
    line-height: 1.5;
}

input:focus, textarea:focus {
    outline: none;
}


table {
    height: 100%;
}
