
// colors
// Be religious about not adding extra ones

$white        : #fff;
$lighter-grey : #f9f9fa;
$light-grey   : #aaa;
$mid-grey     : #777;
$dark-grey    : #555;
$darker-grey  : #222;

$red                   : #a8294d;
$light-red             : lighten($red, 15%);
$green                 : #31a341;
$light-green           : lighten($green, 15%);
$blue                  : #2999FF;
$light-blue            : lighten($blue, 15%);
$yellow                : #ffd44a;
$light-yellow          : lighten($yellow, 5%);
$navy                  : #242a33;
$light-navy            : lighten($navy, 5%);
$purple                : #a5a8ff;

$body-text-color       : #555;
$body-background-color : darken($lighter-grey, 2%);
$border-color          : #e9eaed;
$border                : 1px solid $border-color;

// z-index

$z-modal    : 9;
$z-overlay  : 8;
$z-dropdown : 7;
$z-header   : 6;
$z-main-header: 5;
$z-elevated : 2;
$z-base     : 1;
$z-hidden   : 0;
$z-cloak    : -1;

// typography

$font-stack : 'open sans', helvetica, arial, sans-serif;
$icon-stack : 'entypo';

// styling breakpoints
$navbar-collapse-breakpoint: 768px;