
/**
 * Layout
 *
 * This file should contain styles that determine the general layout of
 * the website. Layouts that are specific to views should go in the
 * appropriate module file.
 */

html {
    position: relative;
    -webkit-overflow-scrolling: touch;
}

html, #app {
    width: 100%;
    height: 100%;
    min-width: 300px;
}

#app {
    margin: 0 auto;
}

$topbar-height: 40px;
$status-message-bar-height: 30px;
$subheader-height: 40px;
$footer-height: 70px;
$smart-group-panel-width: 240px;
$currency-switcher-height: 90px;


#app > header {
    height: $topbar-height;
    width: 100%;
}

#app {
    & > .status-message-bar {
        position: absolute;
        top: $topbar-height;
        height: $status-message-bar-height;
        left: 0;
        right: 0;
        line-height: $status-message-bar-height - 3px;
    }
    & > main {
        position: absolute;
        top: $topbar-height;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .status-message-bar + main {
        position: absolute;
        top: $topbar-height + $status-message-bar-height;
        bottom: 0;
        right: 0;
        left: 0;
    }
}


#app > main {
    .toggle-sidebar {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-top: 0;
        transition: border-color .2s ease;

        &.showing-sidebar {
            border-bottom-color: $white;
            i {
                background-color: $white;
            }
        }
    }

    #subheader {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        right: $smart-group-panel-width;
        z-index: 0;

        @media (max-width: $navbar-collapse-breakpoint) {
            right: 100px;
        }

        height: $subheader-height;
        background: $white;
        border-bottom: $border;
        line-height: $subheader-height - 2px;
        font-size: 13px;
        color: $dark-grey;
        box-shadow: 0px 2px 10px rgba(black, 0.05);

        & > * {
            display: inline-block;
            vertical-align: top;
        }

        .group-name {
            font-weight: 600;
            margin-right: 10px;
            color: rgba($blue, 0.7);
        }

        .natural-language-filter-display {
            flex-grow: 1;
        }

        .query-modifier {
            font-size: 10px;
        }

        .compact-group-select {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 3px 6px;
            border-right: none;
            justify-content: space-between;

            .compact-group-select-dropdown {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 2px;
                    z-index: 1000;
                    background-color: $white;
                    height: 16px;
                    line-height: 16px;
                }

                select {
                    height: 20px;
                    width: 100%;
                    padding-right: 6px;
                    background-color: $white;
                    display: flex;
                    border: 1px solid $border-color;
                    font-size: 12px;
                    cursor: pointer;
                    border-radius: 4px;
                    text-overflow: ellipsis;

                }
            }
        }

        .currency-switcher-select,
        .hierarchy-select,
        .compact-group-select {
            align-items: flex-start;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }

            .select-title {
                width: 100%;
                line-height: 10px;
                font-size: 10px;
                margin-right: 10px;
                display: inline-flex;
                align-items: center;
                text-transform: capitalize;
                white-space: nowrap;
                user-select: none;
            }
        }

        .currency-switcher-select {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 3px 6px;
            border-right: none;
            justify-content: space-between;

            .currency-switcher-dropdown {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    z-index: 1000;
                    background-color: $white;
                    height: 16px;
                    line-height: 16px;
                }

                select {
                    min-width: 75px;
                    height: 20px;
                    background-color: $white;
                    border: 1px solid $border-color;
                    font-size: 12px;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
            }
        }

        .hierarchy-select {
            height: 100%;
            min-width: 156px;
            display: flex;
            position: relative;
            padding: 3px 6px;
            margin-right: 5px;

            .hierarchy-select-dropdown {
                display: flex;
                align-items: center;
                position: relative;

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 2px;
                    top: 9px;
                    z-index: 1000;
                    background-color: $white;
                    height: 16px;
                    line-height: 16px;
                }

                select {
                    width: 94px;
                    height: 28px;
                    background-color: $white;
                    border: 1px solid $border-color;
                    font-size: 12px;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
            }

            &.change-small {
                @media (min-width: $navbar-collapse-breakpoint + 1) {
                    align-items: center;

                    .hierarchy-select-dropdown i {
                        top: 5px;
                    }
                }

                @media (max-width: $navbar-collapse-breakpoint) {
                    min-width: 100px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 3px 6px;
                    opacity: 0.7;
                    border-right: none;
                    justify-content: space-between;

                    .hierarchy-select-dropdown {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        position: relative;

                        i {
                            top: 2px;
                        }

                        select {
                            min-width: 75px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .label {
            color: $dark-grey;
        }
    }

    #view {
        transform: translateZ(0);
        position: absolute;
        left: 0; bottom: 0;
        top: $subheader-height;
        overflow: auto;
        right: $smart-group-panel-width;
        @media (max-width: $navbar-collapse-breakpoint)
        {
            right: 0;
        }

        &.full-view {
            right: 0;
        }

        &.sidebar-animating {
            transition: right 0.3s ease;
        }

    }

    #sidebar {
        position: absolute;
        top: 0; right: 0; bottom: 0;
        width: $smart-group-panel-width;

        .sidebar-content {
            transition: transform 0.3s ease;
        }

        .hide-sidebar-content {
            transform: translateX($smart-group-panel-width);
        }

        // This is the time filter
        .smart-groups-filter-time {
            float: left;
            width: $smart-group-panel-width - 40px;
        }

        @media (max-width: $navbar-collapse-breakpoint) {
            width: 0;
            .smart-groups-filter-time {
                float: right;
                width: 112px;

                button {
                    line-height: 1;

                    .filter-display-date-container,
                    span {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .filter-display-date-description {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                        }

                        font-size: 11px;
                        background: none;
                        border: none;

                        .filter-display-separator {
                            display: none;
                        }
                    }
                }

                .icon-calendar {
                    font-size: 14px;
                    margin-right: 10px;
                }

                span {
                    font-size: 10px;
                    display: inline-block;
                }
            }
        }


        // This is the part below the time filter
        & > main {
            position: absolute;
            right: 0; left: 0; bottom: 0;
            top: $subheader-height;
            display: flex;
            flex-direction: column;
            flex-wrap: no-wrap;
            justify-content: center;
            align-content: center;
            box-shadow: 2px 2px 20px rgba(black, 0.1);
            border-left: 1px solid darken($border-color, 2%);
            z-index: 0;

            .smart-groups-panel {
                flex: 1 1 auto;
                align-self: stretch;
                background: $white;
                position: relative;
                transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
            }

            .query-modifiers {
                flex: 0 0 auto;
                align-self: stretch;
            }
        }

        // This is the smart groups filter modal
        .smart-groups-filter-container {
            position: fixed;
            top: $topbar-height;
            right: $smart-group-panel-width;
            left: 0;
            bottom: 0;
        }
    }

    #system-message {
        height: $subheader-height;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: $white;
        border-top: $border;
        color: $darker-grey;
        font-size: 13px;
        line-height: $subheader-height - 2px;
        padding: 0 10px;

        & ~ #view-container {
            bottom: $subheader-height;
        }

        .system-icon {
            font-size: 16px;
            position: relative;
            top: 2px;
        }
    }

    .hide-small {
        @media(max-width: $navbar-collapse-breakpoint) {
            display: none !important;
        }
    }
    .hide-large {
        @media(min-width: $navbar-collapse-breakpoint + 1) {
            display: none !important;
        }
    }
}


#app > footer {
    position: absolute;
    height: $footer-height;
    left:   0;
    bottom: 0;
    right:  0;
}
