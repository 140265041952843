@use "sass:math";

.view-customer-details {
    position: absolute;
    top: 20px; left: 40px; right: 40px; bottom: 20px;

    $header-height: 60px;
    $transactions-width: 210px;
    $transaction-details-header-height: 40px;

    & > header {
        position: absolute;
        left: 0; right: 0; top: 0;
        height: $header-height;

        .navigation {
            font-size: 20px;
            color: $mid-grey;
            display: table;

            i {
                padding-right: 5px;
                position: relative;
                top: 2px;
                opacity: 0.5;
                font-size: 24px;
            }

            .customers-link {
                vertical-align: middle;
                font-weight: 300;
                height: $header-height;
                display: table-cell;
                border-right: $border;
                padding-left: 10px;
                padding-right: 20px;
                padding-bottom: 6px;
                font-size: 20px;
                color: $mid-grey;

                &:active {
                    box-shadow: inset 0px 1px 2px rgba(black, 0.1);
                }
                &:hover {
                    color: $blue;
                }
            }

            .customer-name {
                vertical-align: middle;
                display: table-cell;
                padding-left: 20px;
                color: $dark-grey;
            }
        }
    }

    & > main {
        position: absolute;
        left: 0; right: 0; bottom: 0;
        top: $header-height + 20px;
    }

    .page-select {
        display: block;
        width: $transaction-details-header-height * 2;
        clear: both;

        button {
            display: block;
            height: $transaction-details-header-height - 1px;
            width: $transaction-details-header-height;
            float: left;
            border: none;
            border-right: $border;
            color: $mid-grey;
            font-size: 24px;
        }
    }

    .customer-transaction-details-header {
        position: absolute;
        left: 0; right: 0; top: 0;
        height: $transaction-details-header-height;
        border-bottom: $border;

        .transaction-count {
            padding: 0;
            padding-left: 10px;
            line-height: $transaction-details-header-height;
            color: $mid-grey;
            display: inline-block;
            font-size: 12px;
        }
    }

    .customer-transactions {
        position: absolute;
        left: 0; bottom: 0;
        top: $transaction-details-header-height;
        width: $transactions-width;
        overflow-y: scroll;
        overflow-x: hidden;
        user-select: none;
        transform: translate3D(0,0,0);
        background: $lighter-grey;
        box-shadow: inset -1px 0px 0px $border-color;

        ul {
            height: 100%;
            width: 100%;
        }

        .customer-transaction {
            border-bottom: $border;
            border-right: $border;
            padding: 20px;
            padding-right: 25px;
            color: rgba($dark-grey, 0.7);
            background: $lighter-grey;

            &:hover {
                cursor: pointer;
                color: $dark-grey;
            }

            &.selected {
                color: $blue;
                background: $white;
                position: relative;

                &:after {
                    $size: 15px;
                    content: " ";
                    position: absolute;
                    right: - math.div($size, 2) - 2px;
                    top: 50%;
                    margin-top: - math.div($size, 2);
                    display: block;
                    width: $size;
                    height: $size;
                    background: $white;
                    box-shadow: 1px 1px 2px rgba(black, 0.08);
                    border: 1px solid darken($border-color, 1.5%);
                    z-index: $z-elevated;
                    transform: rotate(45deg);
                }
            }
        }

        .transaction-timestamp {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 14px;
        }

        .transaction-count,
        .transaction-total {
            display: inline-block;
            font-weight: 14px;
            font-style: italic;
            opacity: 0.8;
        }

        .transaction-total {
            float: right;
        }
    }

    .customer-transaction-summary {
        margin-bottom: 30px;

        h1 {
            color: $dark-grey;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        & > main {
            padding-left: 15px;
        }

        [label] {
            text-transform: capitalize;
            font-size: 0.9em;
            line-height: 1.8;
            clear: both;

            &:before {
                content: attr(label);
                opacity: 0.5;
                width: 7em;
                display: block;
                float: left;
                text-transform: none;
            }
        }

        .transaction-summary-email[label] {
            text-transform: none;
        }
    }

    .customer-transaction-details {
        position: absolute;
        bottom: 0; right: 0;
        top: $transaction-details-header-height;
        left: $transactions-width;
        overflow-y: scroll;
        overflow-x: hidden;

        padding: 30px 40px;
        padding-bottom: 60px;
        transform: translate3D(0,0,0);
    }

    .customer-transaction-items {
        z-index: $z-hidden;
        height: auto;
        font-size: 0.9em;
        width: 100%;
        table-layout: fixed;

        th, td {
            height: auto;
            padding: 1em;
            margin: 0;
            background: none;
        }

        tr {
            border: $border;
        }

        td {
            height: 50px;
            line-height: 1.5;

            &.item-image {
                height: 100%;
                display: flex;

                img {
                    height: 100px;
                    border-radius: 6px;
                }
            }
        }

        th {
            color: #aaa;
        }

        .item-name {
            width: 30%;
        }

        .item-count {
            opacity: 0.5;
            display: block;

            &:before {
                content: '× ';
                position: relative;
                top: 2px;
            }
        }

        .item-unit-price {
            opacity: 0.5;
            display: block;
        }
    }
}
