@use 'sass:math';

#user-menu {
    position: absolute;
    top: 0; right: 0;
    min-height: $topbar-height;
    width: $smart-group-panel-width;
    z-index: $z-elevated + 6;
}

.user-menu {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    line-height: $topbar-height;
    color: $white;
    background: $navy;

    .hide-small {
        @media(max-width: $navbar-collapse-breakpoint){
            display: none;
        }
    }

    &:hover, &.open {
        .dropdown-toggle {
            background-color: lighten($navy, 5%);
        }
        .icon-menu {
            opacity: 0.6;
        }
    }

    &:hover .user-menu-face:hover {
        background-color: lighten($navy, 6%);
    }

    .user-menu-face {
        border-left: 1px solid rgba(white, 0.05);
        cursor: pointer;

        .labels {
            font-size: 10px;
            position: absolute;
            bottom: 0;
            right: 30px;
            top: 0px;
            left: 10px + $organization-logo-size + 10px;
            display: inline-block;
            line-height: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .labels.no-logo {
            left: 20px;
        }

        .organization-logo {
            position: absolute;
            left: 10px;
            top: math.div(($topbar-height - $organization-logo-size), 2);
            width: $organization-logo-size;
            height: $organization-logo-size;
            display: inline-block;
            border-radius: 100%;
            background: #444;
        }

        .user-label, .organization-label {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            letter-spacing: 2px;
            position: relative;
            top: -1px;
            flex: none;
        }

        .user-label {
            font-size: 8px;
            opacity: 0.7;
            line-height: 1.25;
        }

        .organization-label {
            display: block;
            font-size: 10px;
            line-height: 1.3;
        }

        .icon-menu {
            position: absolute;
            right: 0; top: 0; bottom: 0;
            font-size: 20px;
            opacity: 0.3;
            width: $smart-group-height;
            text-align: center;
        }
    }

    .user-menu-face {
        position: absolute;
        top: 0; right: 0; bottom: 0;
        height: $topbar-height;
        width: $smart-group-panel-width;
    }

    .user-menu-dropdown {
        position: absolute;
        top: $topbar-height - 3px;
        transition: none;
        left: 0; right: 0;
        z-index: 9000;
        max-height: 500px;
        overflow-y: hidden;
        border-radius: 0;
        border-bottom-left-radius: 3px;
        border-left: 1px solid rgba(white, 0.15);
        border-top: 1px solid rgba(white, 0.15);

        .submenu {
            padding: 0;
            cursor: default;

            &:hover {
                color: $white;
                background: $navy;
            }

            &:first-child h1 {
                border-top: none;
            }

            .organizations {
                max-height: 300px;
                overflow: auto;
                overflow-x: hidden;
            }

            h1 {
                height: 27px;
                line-height: 26px;
                font-size: 8px;
                color: rgba($white, 0.8);
                background-color: lighten($navy, 3%);
                border-top: 1px solid rgba(white, 0.05);
                border-bottom: 1px solid rgba(white, 0.05);
                padding: 0 10px;
                cursor: default;
                user-select: none;
            }

            li {
                padding: 0 10px;
                line-height: 35px;
                font-size: 11px;
                font-weight: 400;
                text-transform: initial;
                letter-spacing: 0px;
                color: rgba($white, 0.6);
                cursor: normal;
                &.active {
                    color: $yellow;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(white, 0.05);
                }
                &:hover {
                    color: $yellow;
                    background: lighten($navy, 2%);
                }
            }
        }

        li {
            display: block;
            text-align: left;
            i {
                display: inline-block;
                &:before {
                    opacity: 0.5;
                    margin-right: 6px;
                    margin-left: -1px;
                }
            }
        }
    }
}
