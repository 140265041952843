
.item-actions-panel {
    width: 100%;
    max-width: 100%;
    border: none;
    box-shadow: none;
    user-select: none;

    display: flex;
    flex-direction: row;

    .label {
        font-size: 9px;
        position: absolute;
        top: 8px;
        padding-left: 1px;
        color: $mid-grey;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: $z-elevated + 2;
        pointer-events: none;
        font-weight: 600;
    }

    .group-by-select {
        border-right: $border;
        margin: 0 10px;
        flex: 1 1 auto;
        align-self: stretch;
        padding-top: 4px;

        .group-by-label {
            font-size: 9px;
            padding-left: 1px;
            color: #777;
            text-transform: uppercase;
            letter-spacing: 1px;
            pointer-events: none;
            font-weight: 600;
        }

        ul:before {
            content: ' ';
            display: block;
            height: 12px;
            width: 100%;
        }

        li {
            font-size: 10px;
            color: $dark-grey;
            padding: 0 8px;
            border-radius: 10px;
            display: inline-block;
            height: 24px;
            line-height: 22px;
            border: $border;
            margin-right: 10px;
            margin-bottom: 10px;

            &:not(.selected) {
                background: $lighter-grey;
            }

            &.selected, &:hover {
                cursor: pointer;
                opacity: 1.0;
                color: $white;
                background: $blue;
                border-color: $blue;
            }
        }
    }

    .right {
        flex: 0 0 auto;
        align-self: stretch;
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        .row {
            flex: 0 0 auto;
            align-self: stretch;
            vertical-align: middle;
            align-items: flex-end;
        }
    }

    .button-toggle-images {
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        width: auto;
    }

    .model-select {
        position: relative;
        vertical-align: middle;
        font-size: 12px;
        color: $mid-grey;
        display: inline-block;
        float: left;
        margin-right: 9px;

        &.model-select-display-by .selected {
            width: 140px;
        }
        &.model-select-sort-by .selected {
            width: 150px;
        }
        &.model-select-limit .selected {
            width: 60px;
        }

        &:before {
            content: ' ';
            background: $white;
            z-index: $z-elevated;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            pointer-events: none;
        }

        .selected {
            position: relative;
            display: table;
            margin-top: 24px;
            pointer-events: none;
            padding-left: 6px;
            padding-right: 3px;
            border: $border;
            z-index: $z-elevated + 1;
            height: 24 * 2px;

            .property {
                display: table-cell;
                vertical-align: middle;
                position: relative;
                .property-label {
                    font-size: 11px;
                    line-height: 18px;
                    display: inline-block;
                    width: calc(100% - 20px);
                }
                i {
                    position: absolute;
                    right: 0;
                    font-size: 14px;
                    top: 50%;
                    margin-top: -6px;
                    color: $light-grey;
                }
            }

            .group {
                font-weight: 300;
            }
        }

        select {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            width: 100%; height: 100%;
            border: none;
            border-radius: 0px;
            font-size: 14px;
            background: none;
            color: $mid-grey;
        }

        &:hover {
            .selected {
                color: $mid-grey;
                background: darken($white, 2%);
            }
        }
    }

    button.metric-expand {
        width: 90px;
        height: 48px;
        padding: 3px;
        line-height: 1;
        position: relative;
        top: 24px;
        margin-right: 10px;
        color: $mid-grey;
        float: left;
        display: inline-block;

        i {
            width: auto;
            opacity: 0.5;
            font-size: 12px;
            margin-left: -15px;
        }
        span {
            font-weight: 600;
            font-size: 9px;
            letter-spacing: 1px;
            text-transform: uppercase;
            width: 40px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            line-height: 1.3;
        }
        &.active {
            border-color: rgba($blue, 0.5);
            color: $blue;
        }
    }

    .metric-selector {
        position: absolute;
        height: 400px;
        width: 300px;
        right: 10px;
        top: 72px;
        z-index: $z-modal;
        border: $border;
        background: white;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 3px 7px rgba(black, 0.08);

        & > header {
            $header-height: 40px;
            height: $header-height;
            border-bottom: $border;
            display: flex;

            & > button {
                line-height: 100%;
                height: $header-height - 1px;
                display: block;
                border: none;
                border-left: $border;
                font-size: 11px;
                min-width: 70px;
                color: $mid-grey;
            }
            & > input {
                flex-grow: 1;
                height: $header-height - 1px;
                border: none;
                display: block;
                padding-right: 10px;
                font-size: 12px;
            }
            & > i {
                display: block;
                width: 30px;
                font-size: 13px;
                color: $mid-grey;
                opacity: 0.6;
                height: $header-height - 1px;
                line-height: $header-height - 1px;
                text-align: center;
            }
            &:not(.active) {
                & > button {
                    display: none;
                }
            }
            &.active {
                & > i, & > input {
                    color: $blue;
                }
            }
        }

        .metric-selector-tree {
            flex: 1 1;
            overflow-y: auto;
            .jstree-anchor {
                color: $dark-grey;
                font-size: 13px;
                .jstree-checkbox {
                    margin-right: 4px;
                }
            }
            .jstree-search {
                color: $darker-grey;
            }
        }
    }
}