
#app.route-metrics {
    #view {
        right: 0;
    }
    .smart-groups-panel,
    .toggle-sidebar {
        display: none;
    }
    #sidebar {
        .smart-groups-filter-time {
            width: $smart-group-panel-width;
        }
        .date-select-container:after {
            right: 100px;
        }
    }
}

.view-metrics {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background: $body-background-color;

    & > .loadable {
        background-color: $body-background-color;
        opacity: 0;
        &.loading {
            opacity: 1;
        }
        &:after {
            z-index: $z-modal;
        }
        &:before {
            z-index: $z-modal;
        }
    }

    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    $header-height: 160px;
    $metrics-funnel-node-actions-height: 30px;

    tabs-with-menu {
        z-index: $z-elevated;
    }

    // This is the top section:
    // - selected filters
    // - group by
    .metrics-funnel-breadcrumb {
        flex: 0 1 auto;
        align-self: stretch;
        position: relative;
        box-shadow: 0px 2px 10px rgba(black, 0.05);
        background: $white;

        .funnel-node {
            display: inline-block;

            .pellet {
                margin-left: 0px;
            }

            .separator {
                position: relative;
                top: 2px;
                margin-left: 8px;
                margin-right: 5px;
                color: $light-grey;
            }

            .property:after {
                content: ' |';
                color: $light-grey;
            }

            &.selected {
                &:not(:hover) {
                    .property {
                        color: $blue;
                    }
                    .value {
                        color: $mid-grey;
                    }
                }
                .property {
                    font-weight: 600;
                    font-style: normal;
                }
                .value {
                    font-style: italic;
                    font-weight: 400;
                }
                .pellet {
                    border: 1px solid $blue;
                }
            }
        }

        button {
            text-decoration: underline;
            font-weight: 600;
            background: none;

            &:hover {
                background: none;
                color: $blue;
            }
            &:active {
                box-shadow: none;
            }
        }

        .pellet {
            font-size: 11px;
            color: $dark-grey;
            padding: 6px 8px;
            padding-top: 5px;
            border-radius: 14px;
            display: inline-block;
            border: $border;
            margin-left: 10px;
            margin-bottom: 8px;

            .property {
                text-transform: lowercase;
                font-weight: 400;
                font-style: italic;
            }

            .value {
                text-transform: capitalize;
                font-weight: 600;
            }

            &:not(.selected) {
                background: $lighter-grey;
            }

            &.selected, &:hover {
                cursor: pointer;
                opacity: 1.0;
                color: $white;
                background: $blue;
                border: 1px solid $blue;
            }
        }

        .funnel-state {
            position: relative;
            border-bottom: $border;
            background: $white;
            min-height: 76px;
            padding: 0 10px;

            & > .help-text {
                position: absolute;
                top: 26px;
                left: 10px;
                font-style: italic;
                font-size: 14px;
                color: $dark-grey;
                height: 24px;
                display: block;
                padding-bottom: 8px;

                i {
                    position: relative;
                    top: 1px;
                    margin-right: 5px;
                    font-size: 16px;
                    color: $light-grey;
                }

                .pellet {
                    margin-left: 0px;
                    font-style: normal;
                }
            }

            .pellets {
                padding-bottom: 8px;
            }
        }

        .funnel-state, .available-properties {

            .properties-container {
                padding-right: 5px;
            }

            span.no-border {
                button {
                    border:none;
                }
            }

            & > header, .properties-container > header {

                h1, button {
                    display: inline-block;
                    line-height: 15px;
                    height: 15px;
                    font-size: 10px;
                    color: $mid-grey;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                    border: none;
                }
                h1 {
                    margin-top: 10px;
                    margin-bottom: 11px;
                }
                button {
                    display: inline-block;
                    font-size: 10px;
                    margin-left: 8px;
                    color: rgba($blue, 0.5);
                    text-decoration: none;
                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }
            }
        }

        .available-properties {
            display: flex;
            flex-direction: row;
            position: relative;
            background: $white;
            min-height: 70px;

            .properties-container > header {
                padding-left: 10px;
            }

            .properties-container {
                flex: 1;
            }

            .pellets {
                padding-bottom: 8px;
            }
        }

        .buttons-holder {
            flex: 0 0 125px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-left: $border;

            .metrics-selector-container {
                position: relative;
                display: flex;
                height: 100%;
            }

            .panel-button {
                display: block;
                position: relative;
                align-self: center;
                height: 36px;
                width: 100px;
                color: $mid-grey;
                text-decoration: none;

                &.metric-edit-button {
                    width: 90px;
                    height: 50px;
                    flex: 1;

                    i {
                        width: auto;
                        opacity: 0.5;
                        font-size: 12px;
                        margin-left: -12px;
                    }

                    span {
                        font-weight: 600;
                        font-size: 10px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        width: 40px;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        line-height: 1.3;
                    }
                }

                &:hover, &.active {
                    color: $blue;
                    cursor: pointer;
                }

                &.export {
                    border: none;
                    flex: 0 0 30px;
                    display: block;
                    align-self: middle;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 9px;

                    span {
                        font-weight: 600;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        width: 40px;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        line-height: 1.3;
                    }
                }

                &.active {
                    border-color: rgba($blue, 0.5);
                    color: $blue;
                }
            }
        }

        .metric-selector {
            position: absolute;
            height: 400px;
            width: 300px;
            right: 0px;
            top: 50px;
            z-index: $z-modal;
            border: $border;
            background: white;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 3px 7px rgba(black, 0.08);

            $header-height: 45px;

            & > header {
                height: $header-height;
                border-bottom: $border;

                .metric-selector-filter {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    button {
                        line-height: 100%;
                        height: $header-height - 9px;
                        display: block;
                        border-left: $border;
                        margin-right: 3px;
                        font-size: 11px;
                        min-width: 70px;
                        color: $mid-grey;

                        &:not(.active) {
                            display: none;
                        }
                    }

                    input {
                        flex-grow: 1;
                        height: $header-height - 8px;
                        border: none;
                        display: block;
                        padding-right: 6px;
                        font-size: 12px;
                    }

                    i {
                        display: block;
                        width: 30px;
                        font-size: 13px;
                        color: $mid-grey;
                        opacity: 0.6;
                        height: $header-height - 1px;
                        line-height: $header-height - 1px;
                        text-align: center;
                    }
                }
            }

            & > button {
                &:not(.active) {
                    & > button {
                        display: none;
                    }
                }
                &.active {
                    & > i, & > input {
                        color: $blue;
                    }
                }
            }



            .metric-selector-tree {
                flex: 1 1;
                overflow-y: auto;
                .jstree-anchor {
                    color: $dark-grey;
                    font-size: 13px;
                    .jstree-checkbox {
                        margin-right: 4px;
                    }
                }
                .jstree-search {
                    color: $darker-grey;
                }
            }
        }
    }


    // This is the bottom section:
    // - grid views
    // - grid
    .metrics-funnel-node {
        height: 100%;
        width: 100%;
        position: relative;
        flex: 1 1 auto;
        align-self: stretch;

        .grid-container {
            $grid-header-height: 70px;
            $grid-footer-height: 40px;

            position: absolute;
            $padding: 0px;
            top: 0px;
            left: $padding;
            bottom: $padding;
            right: $padding;
            border-top: 1px solid $border-color;

            .grid {
                .sortable:after {
                    display: none
                }
            }

            .grid-new {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                overflow: hidden;
                box-shadow: 0px 1px 5px rgba(black, 0.1);

                .ag-floating-top-container .ag-row:last-child {
                    border-bottom: none;
                }

                .ag-floating-top-viewport {
                    z-index: 0;
                }

                .ag-floating-top-container,
                .ag-pinned-left-floating-top {
                    background-color: #F5F5F5;
                    border-color: #e9eaed;

                    .ag-row {
                        background-color: #F5F5F5;
                        border-color: #e9eaed;

                        .ag-cell {
                            border-right-color: #e9eaed;
                        }
                    }
                }

                .ag-body-viewport .ag-pinned-left-cols-container .ag-row .ag-cell {
                    border-right-color: #e9eaed;
                }

                .ag-pinned-left-floating-top {
                    z-index: 1;
                    box-shadow: 1px 3px 5px rgba(black, 0.1);
                }

                .row-simple-value {
                    height: 84px;
                }

                .row-total-value {
                    height: $grid-footer-height;
                }

                .cell-column-pinned-total-value {
                    overflow: hidden;
                }

                .cell-row-pinned-total-value {
                    border-color: #e9eaed;
                    overflow: hidden;
                }

                .cell-column-pinned-value {
                    font-weight: 600;
                    text-decoration: underline;

                    &:hover {
                        color: $blue;
                        cursor: pointer;
                    }
                }

                .cell-row-value {
                    border-right: $border;
                }


                .cell-row-value,
                .cell-row-pinned-total-value,
                .cell-column-pinned-value,
                .cell-column-pinned-total-value {
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .ag-row .item-image-render span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .ag-body-horizontal-scroll {
                    display: none;
                }
            }
        }
    }
}
