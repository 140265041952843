.perchevron {
    margin-left: 1em;
}

[class*="positive"] .perchevron,
[class*="negative"] .perchevron {
    margin-left: 0;
}

.perchevron:before {
    font-family: "entypo";
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    speak: none;
    display: inline-block;
    position: relative;
    top: 0.1em;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    margin-right: 0.3em;
}

[class*="positive"] .perchevron:before {
    content:'\e87a';
    top: 0.05em;
}

[class*="negative"] .perchevron:before {
    content:'\e877';
}
