
.view-transactions {
    $header-height: 50px;

    position: absolute;
    top: 25px;
    left: 40px;
    right: 40px;
    bottom: 40px;

    & > header {
        position: absolute;
        height: $header-height;
        left: 0; right: 0;

        h1 {
            display: inline-block;
            position: relative;
            left: 4px;
            font-weight: 300;
            font-size: 28px;
            color: $mid-grey;
        }
    }

    & > main {
        position: absolute;
        left: 0; right: 0;
        top: $header-height;
        bottom: 0px;
    }

    .transactions-table {

        .column-timestamp {
            width: 200px;
            text-align: left !important;
        }

        .column-customer {
            text-align: left !important;
            text-transform: capitalize;
        }

        .column-item-count {
            width: 150px;
        }

        .column-total {
            width: 150px;
        }
    }
}
