@use 'sass:math';

.loading-status {
    z-index: $z-elevated;

    $width: 500px;
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left: math.div($width, 2) * -1;

    &:before {
        position: fixed;
        top: $topbar-height;
        left: 0;
        right: 0;
        bottom: 0;
        background: $body-background-color;
        content: ' ';
        z-index: -1;
    }

    .message {
        width: $width;
        font-size: 16px;
        text-align: center;
        line-height: 1.2;
        display: block;
        margin: 0 auto;

        a {
            color: darken($dark-grey, 2%);
            text-decoration: underline;
            color: pointer;
            &:hover {
                color: $darker-grey;
            }
        }

        &.message-loading {
            font-size: 11px;
            color: $dark-grey;
        }

        h1 {
            font-size: 16px;
            font-weight: 600;
            color: $darker-grey;
            margin-bottom: 16px;
        }

        h2 {
            font-size: 14px;
            color: $dark-grey;
        }

        p {
            font-size: 12px;
            margin-top: 80px;
            font-style: italic;
            color: $dark-grey;
            line-height: 1.5;
        }
        .contact-support a {
            display: block;
        }
    }

    .spinner {
        display: block;
        margin: 0 auto;
        opacity: 0.9;
        width: 100px;
        height: 70px;
        background: transparent url('~images/spinner.gif') no-repeat;
        background-position: center center;
        background-size: 100px;
        content: ' ';
        z-index: $z-elevated + 2;
        background: transparent url('~images/spinner.gif') no-repeat;
        background-position: center center;
        background-size: 50px;
    }
}
