@use 'sass:math';

.date-select-container {
    box-shadow: 0px 3px 8px rgba(black, 0.1);
    background: $white;
    border: $border;
    $color-selection:  $blue;
    $color-comparison: #F17CB0;

    $datepicker-cell-count: 8;
    $datepicker-cell-width: 40px;
    $date-select-header-height: 40px;
    $date-select-height: $date-select-header-height + ($datepicker-cell-width * 6) + 4px;
    $date-select-height-gregorian: $date-select-header-height + ($datepicker-cell-width * 7) + 4px;
    $panel-left-width: 200px;
    $panel-right-width: (2 * $datepicker-cell-width * $datepicker-cell-count) + 4px;
    $header-height: 50px;

    width: $panel-right-width + $panel-left-width;
    @media(max-width: $navbar-collapse-breakpoint) {
        width: $panel-left-width;
    }
    position: relative;
    height: $header-height + $date-select-height * 2;
    max-height: calc(100% - 50px);
    user-select: none;

    .hide-small {
        @media(max-width: $navbar-collapse-breakpoint) {
            display: none;
        }
    }

    &.date-select-container-gregorian {
        height: $header-height + $date-select-height-gregorian * 2;
        .date-select-nrf {
            height: $date-select-height-gregorian;

            .date-pickers .datepicker-month {
                border-bottom: $border;
            }

        }
    }

    & > header {
        position: absolute;
        top: 0; left: 0; right: 0;
        height: $header-height;
        border-bottom: 1px solid $border-color;

        .display {
            line-height: $header-height;
            color: $mid-grey;
            font-size: 13px;
            margin-left: 10px;

            .time {
                font-weight: 600;
                line-height: 1;
                vertical-align: baseline;
                white-space: nowrap;
                text-align: center;
                padding: 2px 4px;
                margin: 0 1px;
                border-radius: 4px;
            }

            .separator {
                margin: 0px 5px;
            }

            .selection-label {
                font-weight: 600;
            }

            .selection-start, .selection-end {
                color: $color-selection;
                background-color: rgba($color-selection, 0.1);
            }

            .comparison-start, .comparison-end {
                color: $color-comparison;
                background-color: rgba($color-comparison, 0.1);
            }
        }

        .buttons {
            position: absolute;
            top: 0; bottom: 0; right: 0;
            width: 250px;
            border-left: $border;
        }

        .button-cancel, .button-save {
            display: inline-block;
            float: left;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 13px;
            text-align: center;
            line-height: $header-height;
            height: $header-height - 1px;
            transition: all 0.1s ease-in-out;
        }

        .button-cancel {
            width: 100% * math.div(1, 3);
            // background: $navy;
            font-weight: 600;
            color: $light-grey;
            cursor: pointer;
            border: none;

            &:hover {
                // background: $light-navy;
                color: $mid-grey;
            }
        }

        .button-save {
            width: 100% * math.div(2, 3);
            font-weight: 700;
            background: $yellow;
            cursor: pointer;
            border: none;

            &.unsaved{
                font-style: italic;
                &:after {
                    content: '*';
                }
            }

            &:hover {
                background: $light-yellow;
            }
        }
    }

    .panel-left {
        position: absolute;
        bottom: 0; left: 0;
        top: $header-height;
        display: flex;
        flex-direction: column;
        @media(max-width: $navbar-collapse-breakpoint) {
            top: 0;
        }
        width: $panel-left-width;
    }

    .panel-right {
        position: absolute;
        left: $panel-left-width;
        top: $header-height;
        right: 0; bottom: 0;
        border-left: $border;
    }


    .comparison-mode-selector {
        $height: 50px;
        flex: 1 1 $height;
        border-bottom: $border;
        padding: 0 17px;
        height: $height;
        box-shadow: inset -3px -1px 10px rgba(0,0,0, 0.04);
        // display: none;
        div {
            display: block;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            span {
                font-size: 12px;
            }
            select {
                font-size: 11px;
            }
            span, select {
                display: block;
            }
        }
        @media(max-width: $navbar-collapse-breakpoint) {
            display: none;
        }
    }

    .predefined-dates {
        flex: auto;
        overflow: scroll;
        border-right: $border;
        background: $body-background-color;

        li {
            width: 100%;
            display: block;
            border-bottom: $border;
            font-size: 11px;
            height: 35px;
            line-height: 35px;
            padding: 0 17px;
            background: $white;
            &.section {
                border-top: 1px solid darken($border-color, 5%);
            }
            span {
                display: block;
                &.active {
                    color: $blue;
                }
                &.disabled {
                    color: $light-grey;
                }
            }
            &:hover:not(.disabled) {
                color: $blue;
                cursor: pointer;
            }
        }
    }

    @mixin datepickers-color($color) {

        .datepicker-day:hover:not(.state-selected-start):not(.state-selected-end):not(.state-disabled):not(.state-blank) {
            background: rgba($color, 0.6) !important;
        }

        .state-in-range {
            background: rgba($color, 0.1) !important;
            border-top: 1px solid rgba($color,0.5) !important;
            border-bottom: 1px solid rgba($color,0.5) !important;
        }

        .datepicker-start {
            .state-selected-end {
                background: rgba($color, 0.5) !important;
                box-shadow: inset -1px 0px rgba($color,0.5);
                border-bottom-color: rgba($color,0.7) !important;
                .date-day, .date-month {
                    color: $white;
                }
            }
            .state-selected-start {
                background: $color !important;
                border-bottom-color: $color !important;
                .date-day, .date-month {
                    color: $white;
                }
            }
        }

        .datepicker-end {
            .state-selected-start {
                background: rgba($color, 0.5) !important;
                border-left: 1px solid rgba($color,0.5) !important;
                border-bottom-color: rgba($color,0.7) !important;
                .date-day, .date-month {
                    color: $white;
                }
            }
            .state-selected-end {
                background: $color !important;
                border-bottom-color: $color !important;
                .date-day, .date-month {
                    color: $white;
                }
            }
        }

        .date-input input {
            color: $color !important;
            user-select: all;
        }
    }

    .datepickers-selection {
        @include datepickers-color($color-selection);
    }
    .datepickers-comparison {
        @include datepickers-color($color-comparison);
    }

    .date-select-nrf {
        position: relative;
        user-select: none;
        height: $date-select-height;
        box-shadow: inset -1px 0px rgba(black, 0.1);

        &:first-child {
            border-bottom: $border;
        }

        & > header {
            position: absolute;
            left: 0; right: 0; top: 0;
            height: $date-select-header-height;
            // border-bottom: $border;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
            background: $white;
            border: $border;
        }

        .date-pickers {

            .date-picker:first-child {
                position: absolute;
                left: 0; top: 0; bottom: 0;
            }

            .date-picker:last-child {
                position: absolute;
                right: 0; top: 0; bottom: 0;
                border-left: $border;
            }

            .date-picker {
                position: relative;

                & > h1 {
                    position: absolute;
                    top: 8px;
                    left: 10px;
                    font-size: 12px;
                    font-style: italic;
                    color: $mid-grey;
                    display: inline-block;
                }

                .date-input input {
                    height: $date-select-header-height;
                    width: 100%;
                    border: none;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-align: center;
                    border-bottom: $border;
                }

                .calendar-month-select {
                    width: 100%;
                    overflow: hidden;
                    background: $white;
                    position: relative;
                    line-height: $datepicker-cell-width;
                    height: $datepicker-cell-width;

                    & > * {
                        // display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                        display: inline-block;
                    }

                    select {
                        height: 20px;
                    }
                    .selects {
                        position: absolute;
                        top: 0; bottom: 0;
                        left: $datepicker-cell-width;
                        right: $datepicker-cell-width;
                    }
                    .select-year {
                        width: $datepicker-cell-width * 1.6;
                    }
                    .select-month {
                        width: $datepicker-cell-width * 3.6;
                        margin-left: 8px;
                    }

                    .select-prev .icon-left-open-mini,
                    .select-next .icon-right-open-mini {
                        font-size: 24px;
                    }

                    button {
                        width: $datepicker-cell-width;
                        background: $white;
                        border: none;
                        color: $dark-grey;
                        font-size: 24px;
                        &:first-child {
                            position: absolute;
                            left: 0; top: 0; bottom: 0;
                        }
                        &:last-child {
                            position: absolute;
                            right: 0; top: 0; bottom: 0;
                        }
                        &:before {
                            width: 30px;
                            height: 30px;
                            background: $white;
                        }
                        &:hover {
                            color: $darker-grey;
                        }
                        &:disabled {
                            color: $light-grey;
                        }
                    }
                }
            }
        }

        .datepicker-month {

            header {
                display: none;
                height: 20px;
                color: $mid-grey;
                line-height: 20px;
                padding-left: 20px + 10px;
                margin-bottom: 4px;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }

        .datepicker-weeks {
            display: table;
            border-collapse: collapse;
        }

        .datepicker-week {
            overflow: hidden;
            display: table-row;

            & > .datepicker-day,
            & > .datepicker-week-number {
                display: table-cell;
                font-size: 9px;
                width:  $datepicker-cell-width;
                height: $datepicker-cell-width;
                vertical-align: middle;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: black;
                }
            }

            .datepicker-week-number:first-child {
                border-top: $border;
            }

            & > .datepicker-day {
                border-top: $border;

                &:not(.state-disabled):not(.state-blank) {
                    cursor: pointer;
                }

                &.state-blank {
                    background: $white;
                }

                &.state-disabled {
                    background: $border-color;
                }

                span {
                    display: block;
                }
            }

            & > .datepicker-week-number {
                color: $mid-grey;
                font-style: italic;
            }
        }

        .date-month {
            font-size: 9px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: rgba(black, 0.3);
            margin-bottom: 4px;
        }

        .date-day {
            font-size: 12px;
            color: rgba(black, 0.7);
            font-weight: 600;
        }

        .datepicker-day {
            background: $white;
        }

        .datepicker-week:last-child .datepicker-day {
            border-bottom: 1px solid $white;
        }

    }
}
