@mixin ui-input-search($height: '50px') {
    .ui-input-search {
        position: relative;
        height: $height;

        input[type='search'] {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 12px;
            padding-left: 1.8em;
            border: none;
            color: #888;
        }

        &:after {
            font-family: $icon-stack;
            content: '\e803';
            position: absolute;
            color: #aaa;
            left: 0;
            top: 0;
            font-size: 2.5em;
            display: block;
            line-height: $height;
            height: $height;
        }

        &:hover:after {
            color: #888;
        }
    }
}

.ui {
    user-select: none;
}

.ui-select {
    position: relative;
    height: 40px;
    display: inline-block;

    select {
        border-radius: 3px;
        appearance: none;
        background-color: transparent;
        outline: none;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;
        padding-right: 20px;
        font-size: 14px;
        border: none;
        color: $mid-grey;

        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        &:after {
            color: $dark-grey;
        }

        select {
            color: $dark-grey;
        }
    }

    &:after {
        position: absolute;
        width: 20px;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        content: '▼';
        z-index: $z-base;
        text-align: right;
        text-align: center;
        line-height: 40px;
        color: $mid-grey;
    }
}

.ui-select2 {
    user-select: none;
    $height: 30px;
    font-size: 11px;
    display: inline-block;
    height: $height;
    overflow: hidden;
    color: $dark-grey;

    &:hover,
    &.active {
        .faceplate {
            border-color: $border-color;
            i {
                opacity: 0.8;
            }
        }
    }

    &.active {
        box-shadow: 0px 1px 3px rgba(black, 0.1);
        .faceplate {
            border-radius: 3px 3px 0px 0px;
        }
        .popup {
            position: absolute;
            border-radius: 0px 0px 3px 3px;
            pointer-events: auto;
        }
    }

    .faceplate {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        border: 1px solid rgba($border-color, 0.8);
        padding: 5px 10px;
        background: $white;
        height: 100%;
        border-radius: 3px;

        .selected {
            flex: 1;
        }

        i {
            flex: 0;
            display: block;
            margin: 0 -4px;
            font-size: 16px;
            opacity: 0.6;
            &:before {
                margin: 0;
            }
        }
    }

    .popup {
        border: $border;
        border-top: none;
        z-index: $z-elevated;
        background: $white;
        box-shadow: 0px 1px 3px rgba(black, 0.1);
        max-height: 300px;
        overflow: auto;
        overflow-y: scroll;
        pointer-events: none;

        li {
            cursor: pointer;

            & > * {
                padding: 15px 10px;
                padding-right: 50px;
            }

            &:not(:last-child) > * {
                border-bottom: $border;
            }

            &.selected,
            &:hover {
                color: $blue;
            }
        }
    }
}

$content-padding: 20px;

button.button-yellow {
    background: $yellow;
    color: $darker-grey;
    border: rgba($navy, 0.8);
    &:hover {
        background: $light-yellow !important;
    }
}

#overlay {
    transition: background-color 0.5s;
    transform: translateZ(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

    &.overlay-enabled {
        background-color: rgba(black, 0.7);
    }
}

.map {
    background-color: $light-blue;
}

.select-container {
    position: relative;
    height: 28px;

    i {
        position: relative;
        right: 15px;
        font-size: 14px;
        color: $darker-grey;
    }

    select {
        transition: all 0.1s ease-in-out;
        user-select: none;
        appearance: none;
        border-radius: 3px;
        border: 1px solid rgba($mid-grey, 0.2);
        overflow: hidden;
        color: $darker-grey;
        margin: 0;
        line-height: 28px;
        padding: 0px 10px;
        padding-right: 40px;
        position: relative;
        left: 10px;
        top: 10px;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            cursor: pointer;
            border: 1px solid rgba($mid-grey, 0.4);
        }
    }
}

.card {
    position: relative;
    border: $border;
    box-shadow: 0px 2px 0px 0px $border-color;
    border-radius: 3px;
    background: $white;
}

.card,
.supertable,
.loadable,
.button-export {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        opacity: 0;
        pointer-events: none;
    }

    h1,
    .loadable-title {
        position: relative;
        z-index: $z-elevated;
    }

    &.empty:not(.loading) {
        pointer-events: none;

        &:first-line {
            font-weight: 600;
        }

        &:after {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            text-align: center;
            content: 'No data found for the selected filter(s) and time range!\a Try expanding the time range or changing your filters.';
            white-space: pre;
            line-height: 1.6em;
            background: $white;
            color: $dark-grey;
            font-size: 14px;
            z-index: $z-elevated;
            opacity: 1;
        }
    }

    &.loading {
        pointer-events: none;

        h1,
        .loadable-title {
            opacity: 0.5;
        }

        &:before {
            z-index: $z-elevated;
            background: white;
            transition: opacity 0.2s;
            opacity: 1;
        }

        &:after {
            transform: scale(1, 1);
            opacity: 0.9;
            z-index: $z-elevated;
            background: transparent url('~images/spinner.gif') no-repeat;
            background-position: center center;
            background-size: 50px;
            transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.button-export {
    font-size: 12px;
    min-width: 60px;
    text-decoration: underline;
    font-weight: 600;
    color: $mid-grey;

    &.loading {
        &:before {
            display: none;
        }
        &:after {
            background-size: 25px;
        }
    }

    &:hover {
        color: $blue;
        cursor: pointer;
    }

    i {
        &:before {
            position: relative;
            top: 1px;
            padding-right: 3px;
        }
    }
}

.percent-positive {
    color: $green !important;
}

.percent-negative {
    color: $red !important;
}

.percent-invert-color.percent-positive {
    color: $red !important;
}

.percent-invert-color.percent-negative {
    color: $green !important;
}

.chart-tooltip .percent-positive {
    color: lighten($green, 10%) !important;
}

.chart-tooltip .percent-negative {
    color: lighten($red, 25%) !important;
}

.loadable {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.sortable {
    position: relative;
    user-select: none;

    &:after {
        transition: transform 0.1s;
        float: right;
        width: 0px;
        content: '\e86e';
    }

    &:hover {
        cursor: pointer;
    }

    &[sort-order='ascending']:after {
        font-size: 12px;
        content: '\e886'; // arrow up
    }

    &[sort-order='descending']:after {
        font-size: 12px;
        content: '\e883'; // arrow down
    }
}

.sortable {
    position: relative;
    user-select: none;

    &:after {
        transition: transform 0.1s;
        float: right;
        width: 0px;
        content: '\e86e';
    }

    &:hover {
        cursor: pointer;
    }

    &[sort-order='ascending']:after {
        font-size: 12px;
        content: '\e886'; // arrow up
    }

    &[sort-order='descending']:after {
        font-size: 12px;
        content: '\e883'; // arrow down
    }

    cursor: pointer;

    &:not(.sortable-before):after {
        font-family: $icon-stack;
        color: rgba($mid-grey, 0.5);
        font-size: 12px;
        position: relative;
        top: 1px;
    }

    &:hover:after {
        color: $mid-grey;
    }

    &[sort-order='ascending'],
    &[sort-order='descending'] {
        color: rgba($blue, 0.8) !important;
        &:after {
            color: rgba($blue, 0.6) !important;
        }
    }
}

$toggle-width: 40px;

// do generic dropdown layout changes here
.dropdown {
    user-select: none;

    .dropdown-toggle:after {
        text-align: center;
        display: block;
        width: $toggle-width;
    }

    .dropdown-menu {
        position: absolute;
        transition: all 200ms cubic-bezier(0.24, 0.22, 0.015, 1), opacity 100ms linear;
        transform: translate(0, 5px);
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: auto;
    }

    &.open > .dropdown-menu {
        visibility: visible;
        opacity: 1;
        z-index: 9;
        transform: translate(0, 0);
    }
}

.ui-dropdown-select {
    $height: 40px;
    background: $white;
    border: $border;
    display: inline-block;
    height: $height;
    position: relative;

    &:hover .ui-dropdown-select-value {
        color: $darker-grey;
    }

    .ui-dropdown-select-value {
        font-size: 0.8em;
        display: inline-block;
        line-height: $height;
        margin-right: $height;
        position: relative;
        top: -1px;
        margin-left: 13px;
        color: $dark-grey;
    }

    .dropdown-toggle {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        line-height: $height;
        cursor: context-menu;

        &:after {
            font-family: $icon-stack;
            content: '\e8a5';
            font-size: 2em;
            font-weight: 200;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            color: rgba($mid-grey, 0.5);
        }

        &:hover {
            cursor: pointer;
            &:after {
                color: $mid-grey;
            }
        }
    }

    &.open > .dropdown-toggle:after {
        content: '\e876';
        color: $mid-grey;
    }
}

.ui-tabs {
    position: relative;
    box-shadow: // inset 0px -2px 0px $lighter-grey
        inset 0px -1px 0px rgba(black, 0.01);
    background: $lighter-grey;
    width: 100%;
    user-select: none;

    ul {
        display: table;
        width: auto;
        height: 100%;
    }

    .ui-tab {
        transition: all 0s;
        box-shadow: inset 0px 5px 0px 0px rgba($blue, 0);
        border-collapse: collapse;
        display: table-cell;
        font-size: 12px;
        min-width: 150px;
        text-align: center;
        vertical-align: middle;
        color: $mid-grey;
        // background: $lighter-grey;
        position: relative;

        input[type='text'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            font-size: 14px;
            padding: 0px;
        }

        &:hover {
            cursor: pointer;
            color: $blue;
        }

        .ui-tab-content {
            padding-top: 4px;
            text-transform: capitalize;
            font-weight: 600;
        }

        &.ui-tab-selected {
            // transition: all 0.2s;
            box-shadow: inset 0px 2px 0px 0px rgba($blue, 0.8), -2px 0px 4px rgba(black, 0.1),
                2px 0px 4px rgba(black, 0.1);
            color: $blue;
            background: $white;
        }

        &:not(.ui-tab-selected) {
            box-shadow: inset -1px 0px 0px $border-color;
            .ui-tab-content {
                padding-top: 5px;
            }
        }
    }
}

.ui-tabs-with-menu {
    display: flex;
    flex-direction: row;
    background: $lighter-grey;
    box-shadow: 0px 1px 0px $border-color;
    border-right: $border;

    .ui-tab-button {
        flex: 0 0 auto;
        height: 30px;
        width: 30px;
        border: none;
        border-left: $border;
        border-right: $border;

        i {
            height: inherit;
            width: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        i::before {
            width: 50%;
            height: 50%;
            margin-left: 0px;
        }
    }

    .ui-view-tabs::-webkit-scrollbar {
        display: none;
    }

    & > .ui-view-tabs {
        ul {
            flex: 1 1 auto;
            height: 30px;
            max-width: 40px;
            width: 40px;

            .dndDraggingSource {
                display: none;
            }

            .dndPlaceholder {
                width: 2px;
                height: inherit;
                background: $blue;
            }
        }
    }

    ul.dropdown-menu {
        max-width: 300px;
        width: 100%;
        height: inherit;
        cursor: pointer;
        overflow-y: visible;
    }
}

.ui-tabs-with-menu-placeholder {
    opacity: 0;
}

.ui-view-tabs {
    position: relative;
    box-shadow: // inset 0px -2px 0px $lighter-grey
        inset 0px -1px 0px rgba(black, 0.01);
    background: $lighter-grey;
    user-select: none;
    overflow-x: auto; // overflow-x: scroll + Firefox + mouse = persistent scrollbar :(
    width: 100%;

    .ui-tab-button {
        border: 0px;
        border-right: 1px solid #e9eaed;
    }

    ul {
        display: table;
        width: 100%;
    }

    .ui-tab {
        transition: all 0s;
        box-shadow: inset 0px 5px 0px 0px rgba($blue, 0);
        border-collapse: collapse;
        display: table-cell;
        font-size: 12px;
        min-width: 150px;
        min-height: 45px;
        text-align: center;
        vertical-align: middle;
        color: $mid-grey;
        // background: $lighter-grey;
        position: relative;

        &:hover {
            cursor: default;
            .tab-icon-content {
                color: $mid-grey;
            }
        }

        .ui-tab-content {
            display: flex;
            flex-direction: row;
            text-transform: capitalize;
            width: auto;

            .tab-inner-content {
                text-align: left;
                text-indent: 2px;
                flex: 1 1 30px;
                margin: 3px;
                text-transform: none;
                border: 1px solid transparent;
                white-space: nowrap;
            }

            input.tab-inner-content {
                border: 1px solid #e9eaed;
                background: white;
                font-size: 12px;
                padding: 0px;
            }

            .tab-icon-content {
                flex: 0 0 30px;
                position: relative;
                font-size: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                ::before {
                    width: 50%;
                    height: 50%;
                }
            }
        }

        &.ui-tab-selected {
            color: $blue;
            background: $white;
            box-shadow: inset 0px 2px 0px 0px rgba($blue, 0.8), -2px -2px 4px rgba(0, 0, 0, 0.1),
                2px -2px 4px rgba(0, 0, 0, 0.1);

            .tab-icon-content {
                color: $light-grey;
            }

            .tab-icon-content:hover {
                color: $dark-grey;
            }

            .tab-icon-content.icon-toggled {
                color: $blue;
            }
        }

        &:not(.ui-tab-selected) {
            box-shadow: inset -1px 0px 0px $border-color;

            cursor: pointer;

            .tab-icon-content {
                color: $light-grey;
            }

            .tab-icon-content:hover {
                color: $dark-grey;
            }
        }
    }
}

.dropdown {
    backface-visibility: hidden;
    $row-height: 40px;

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        background: $navy;
        color: $white;
        font-size: 10px;
        line-height: $row-height;
        font-weight: 800;
        letter-spacing: 1px;
        margin-top: 3px;
        text-transform: uppercase;
        border-radius: 3px;
        backface-visibility: hidden;
        // width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            height: 100%;
            width: 10px;
            background: none;
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track-piece {
            background: $light-navy;
            border-left: 1px solid lighten($navy, 10%);
        }

        &::-webkit-scrollbar-thumb {
            background: lighten($navy, 15%);
            &:hover {
                background: lighten($navy, 20%);
            }
        }

        li {
            width: 100%;
            padding: 0 10px;

            i {
                display: none;
            }

            &:hover {
                cursor: pointer;
                color: $yellow;
                background: lighten($navy, 10%);
            }

            &.selected {
                color: $yellow !important;
            }
        }
    }
}

.ui-table-tabbed {
    $tabs-height: 10px;

    .ui-tabs {
        height: $tabs-height;
        .ui-tab {
            min-width: 100px;
        }
    }

    .ui-table {
        top: $tabs-height;
        border-top: 1px solid lighten($border-color, 2%);
    }
}

%echarts-item {
    position: relative;

    header {
        padding: 20px;
        padding-bottom: 0px;
    }

    .blank {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-size: 13px;
    }
    .echarts-title {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: $darker-grey;
        font-family: $font-stack;
        margin-left: 20px;
        margin-top: 20px;
    }
    .echarts-main {
        padding: 0px 20px;
        padding-bottom: 10px;
        height: 275px;

        .tooltip {
            padding: 2px 3px;
            font-size: 10px;
            line-height: 1.2;

            h1 {
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 8px;
                margin-bottom: 4px;
            }

            .tooltip-series:not(:last-child) {
                margin-bottom: 4px;
            }

            .tooltip-series {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 10px;

                .tooltip-series-label.negative {
                    color: $light-red;
                }
            }
            .tooltip-series-value {
                margin-left: 5px;

                &.negative {
                    color: $light-red;
                }
            }
        }
    }

    .bucket-picker {
        height: 50px;
        font-size: 12px;
        line-height: 50px;

        ul {
            padding: 0;

            li {
                width: 70px;
                display: inline-block;
                line-height: 24px;
                text-align: center;
                cursor: pointer;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 10px;
                color: rgba($mid-grey, 0.7);
                border: 1px solid white;
            }
        }
    }
}

.echarts-item-single {
    @extend %echarts-item;
    margin-bottom: 20px;
    user-select: none;

    & > header {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        padding-top: 4px;
        position: relative;
        z-index: $z-elevated;
    }

    & > main {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.echarts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 4fr));
    grid-gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

    .echarts-item {
        @extend %echarts-item;

        header {
            display: flex;
            justify-content: space-between;
            padding: 0px;
        }

        .echarts-title {
            height: 30px;
            flex: 1 1 auto;
        }

        .chart-picker {
            height: 50px;
            font-size: 12px;
            line-height: 50px;
            margin-right: 10px;
            flex: 0 0 auto;

            ul {
                padding: 0;

                li {
                    width: 34px;
                    display: inline-block;
                    line-height: 24px;
                    text-align: center;
                    cursor: pointer;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                    color: rgba($mid-grey, 0.7);
                    border: 0.5px solid transparent;
                }
            }
        }
    }
}

.bucket-picker, .chart-picker {
    ul > li {
        border-width: 1px !important;
        border-radius: 6px;
        margin-left: 4px;
        &.active {
            color: $blue !important;
            border-color: rgba($blue, 0.5) !important;
        }
        &:not(.active):hover {
            color: $blue;
        }
    }
}
