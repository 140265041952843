

#app.route-stores {
    #view {
        // right: 0;
        background: $body-background-color;
    }
}


.view-stores {

    $header-height: 160px;
    $footer-height: 40px;

    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;

    main {
        display: flex;
        flex-direction: column;
        height: 100%;

        .actions-panel-row {
            width: 100%;
            flex: 0 1 auto;
            border-top: $border;
            box-shadow: 0px 2px 10px rgba(black, 0.05);
            background: $white;
            display: flex;

            .selected-filters {
                display: inline-block;
                min-width: 200px;
                flex-grow: 1;
            }

            .funnel-node {
                display: inline-block;

                .pellet {
                    margin-left: 0px;
                }

                .separator {
                    position: relative;
                    top: 2px;
                    margin-left: 8px;
                    margin-right: 5px;
                    color: $light-grey;
                }

                .property:after {
                    content: ' |';
                    color: $light-grey;
                }

                &.selected {
                    &:not(:hover) {
                        .property {
                            color: $blue;
                        }
                        .value {
                            color: $mid-grey;
                        }
                    }
                    .property {
                        font-weight: 600;
                        font-style: normal;
                    }
                    .value {
                        font-style: italic;
                        font-weight: 400;
                    }
                    .pellet {
                        border: 1px solid $blue;
                    }
                }
            }

            .pellet {
                font-size: 10px;
                color: $dark-grey;
                padding: 6px 8px;
                padding-top: 5px;
                border-radius: 14px;
                display: inline-block;
                border: $border;
                margin-left: 10px;
                margin-bottom: 8px;

                .property {
                    text-transform: lowercase;
                    font-weight: 400;
                    font-style: italic;
                }

                .value {
                    text-transform: capitalize;
                    font-weight: 600;
                }

                &:not(.selected) {
                    background: $lighter-grey;
                }

                &.selected, &:hover {
                    cursor: pointer;
                    opacity: 1.0;
                    color: $white;
                    background: $blue;
                    border: 1px solid $blue;
                }
            }

            .funnel-state, .available-properties {

                header {
                    padding-top: 5px;

                    h1, button {
                        display: inline-block;
                        line-height: 15px;
                        height: 15px;
                        font-size: 10px;
                        color: $mid-grey;
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }
                    h1 {
                        margin-bottom: 11px;
                    }
                    button {
                        display: inline-block;
                        font-size: 10px;
                        margin-left: 8px;
                        color: rgba($blue, 0.5);
                        text-decoration: none;
                        &:hover {
                            color: $blue;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .funnel-state {
                border-right: $border;
                position: relative;
                background: $white;
                padding: 0px 10px;
                min-height: 76px;

                & > .help-text {
                    position: absolute;
                    top: 32px;
                    left: 10px;
                    font-style: italic;
                    font-size: 14px;
                    color: $dark-grey;
                    height: 24px;
                    display: block;
                    padding-bottom: 8px;

                    i {
                        position: relative;
                        top: 1px;
                        margin-right: 5px;
                        font-size: 16px;
                        color: $light-grey;
                    }

                    .pellet {
                        margin-left: 0px;
                        font-style: normal;
                    }
                }

                .pellets {
                    padding-bottom: 8px;
                }
            }

            .dropdown-actions {
                flex-shrink: 0;
                padding-left: 15px;
            }

            .available-properties {
                position: relative;
                padding: 0px 10px;
                background: $white;
                min-height: 70px;
                .pellets {
                    padding-bottom: 8px;
                }
            }
        }

        .store-text-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            align-items: center;

            color: $mid-grey;
            font-size: 14px;
            height: 40px;
            font-style: italic;

            .natural-language {
                display: inline-block;
                flex: 1;
                margin: 0 10px;

                span {
                    color: $dark-grey;
                    &:not(.metric) {
                        text-transform: lowercase;
                    }
                }
            }

            .actions {
                flex: 0 1 auto;
                height: 100%;

                button {
                    display: inline-flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: center;
                    position: relative;
                    top: 12px;
                    float: right;
                    line-height: 1;
                    color: $dark-grey;
                    height: 30px;
                    padding: 5px 7px;
                    padding-right: 10px;
                    font-size: 11px;
                    border: $border;
                    margin: 0px 5px;

                    &:first-child {
                        margin-right: 0;
                    }
                    &.line {
                        margin-right: 0px;
                        border-right: 0px;
                    }
                    &.stacked-area {
                        margin-left: 0px;
                    }

                    &:hover, &.selected {
                        background: $white;
                        color: $blue;
                        i:before {
                            color: $blue;
                        }
                    }

                    i {
                        line-height: 1;
                        width: auto;
                        flex: 1;
                        position: relative;
                        top: -0.5px;
                        &:before {
                            color: $dark-grey;
                            padding-right: 7px;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        .store-chart-container {
            flex: 1 1 auto;
            padding: 10px;
            padding-top: 20px;
            border-radius: 0;

            .highcharts-container {
                top: 0;
                bottom: 0;
            }

            .highcharts-axis text,
            .highcharts-axis-labels text,
            .highcharts-axis-labels span {
                font-family: $font-stack !important;
                text-decoration: none !important;
                fill: $dark-grey !important;
                color: $dark-grey !important;
                text-transform: none !important;
                font-weight: 400 !important;
            }

            .highcharts-axis text {
                font-size: 14px !important;
            }

            .highcharts-axis-labels text,
            .highcharts-axis-labels span {
                font-size: 12px !important;
            }

            svg > text {
                display: none;
            }

            .store-chart {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
            }
        }
    }


    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $footer-height;

        .button-export {
            position: absolute;
            right: 0px;
            top: 0px;
            height: $footer-height;
            padding: 0 4px;
            padding-top: 10px;

            font-size: 12px;

            &:hover {
                cursor: pointer;
                color: $blue;
            }
        }
    }

}

