.store-actions-panel {
    border-bottom: $border;
    background: $white;
    border-left: $border; border-right: $border;
    user-select: none;

    .metrics-funnel-breadcrumb header {
        h1 {
            display: inline-block;
        }
        .hint {
            padding-left: 5px;
            color: $mid-grey;
            font-size: 11px;
        }
    }

    .filter-funnel-node .pellet {
        pointer-events: none;
        background: $white !important;
    }

    .label {
        font-size: 9px;
        position: absolute;
        top: 8px; left: 0;
        color: $mid-grey;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: $z-elevated + 2;
        pointer-events: none;
        font-weight: 600;
        padding-left: 2px;
    }

    .group-by-select {
        position: absolute;
        bottom: 0; left: 10px; top: 0;

        ul:before {
            content: ' ';
            display: block;
            height: 24px;
            width: 100%;
        }

        li {
            font-size: 10px;
            color: $dark-grey;
            padding: 0 8px;
            border-radius: 10px;
            display: inline-block;
            height: 24px;
            line-height: 22px;
            border: $border;
            margin-right: 10px;
            margin-bottom: 10px;

            &:not(.selected) {
                background: $lighter-grey;
            }

            &.selected, &:hover {
                cursor: pointer;
                opacity: 1.0;
                color: $white;
                background: $blue;
                border-color: $blue;
            }
        }
    }

    .model-select {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
        color: $mid-grey;
        float: right;
        margin-top: 3px;

        &.model-select-small {
            min-width: 100px;
            .selected {
                padding-right: 0px;
            }
        }

        &.model-select-med {
            min-width: 200px;
            .selected {
                padding-right: 0px;
            }
        }

        &:before {
            content: ' ';
            background: $white;
            z-index: $z-elevated;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            pointer-events: none;
        }

        .selected {
            position: relative;
            display: inline-block;
            margin-top: 27px;
            line-height: 24px;
            pointer-events: none;
            font-size: 10px;
            padding-left: 6px;
            padding-right: 3px;
            border: $border;
            z-index: $z-elevated + 1;
            width: calc(100% - 20px);

            .property {
                i {
                    color: $light-grey;
                    padding-left: 10px;
                    float: right;
                }
            }

            .group {
                font-weight: 300;
            }
        }

        select {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            width: 100%; height: 100%;
            border: none;
            border-radius: 0px;
            font-size: 14px;
            background: none;
            color: $mid-grey;
        }

        &:hover {
            .selected {
                color: $mid-grey;
                background: darken($white, 2%);
            }
        }
    }
}