@import 'view-map/custom-mapboxGL';

.view-map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;

    .map-container {
        border: none;
    }

    .new-map {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: $z-base;
    }

    .layer-selector {
        position: absolute;
        top: 10px;
        right: 10px;
        overflow: hidden;
        z-index: $z-base + 1;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);

        .selector-item {
            padding: 4px 0;
            height: 26px;
            width: 100px;
            padding-left: 10px;
            font-size: 10px;
            display: flex;
            align-items: center;
            color: #DBDBDB;
            background-color: white;
            text-transform: uppercase;
            opacity: 0.8;
            cursor: pointer;

            &.customers.selected {
                background-color: #ffd44a;
                font-weight: 600;
                color: #242a33;
            }

            &.stores.selected {
                background-color: #AE8CD1;
                font-weight: 600;
                color: #242a33;
            }

            &:not(.selected):hover {
                background-color: #F9F9FB;
            }
        }
    }
}
