@use 'sass:math';

.smart-groups-filter-container {
    z-index: 999;

    .loading {
        position: absolute !important;
    }

    .smart-groups-filter {
        border-radius: 4px;
    }
}

#app.route-reportingReports,
#app.route-reportingSchedules {
    #view {
        right: 0;
        top: 0;
        background: $body-background-color;
    }
    .smart-groups-panel {
        display: none;
    }
    .modal {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

trix-toolbar {
    .button_group {
        border: none;
        border-radius: 0px;
        margin: none;

        button {
            border: none !important;
        }
        button.active {
            background: rgba($blue, 0.4);
        }
    }
    .history_tools {
        display: none;
    }
    .strike,
    .code,
    .link {
        display: none;
    }
}

.trix-content {
    font-size: 12px;
    font-family: $font-stack;
    strong {
        font-weight: 600;
    }
    em {
        font-weight: 400;
        font-style: italic;
    }
    ul {
        li {
            list-style-type: disc;
            li {
                list-style-type: circle;
                li {
                    list-style-type: square;
                }
            }
        }
    }
    ol {
        li {
            list-style-type: decimal;
            li {
                list-style-type: lower-alpha;
            }
        }
    }
    strong em {
        font-weight: 600;
    }
}

trix-editor {
    min-height: 70px;
    border: $border;
    color: $dark-grey;
    padding: 0.6em 0.8em;
    font-size: 12px;
    border-radius: 0px;
    line-height: 1.4em;
}

$list-action-height: 50px;

// Generic style for list + panels type layout
.view-reporting-list {
    $panel-min-width: 290px;
    $sidepanel-width: 320px;
    $header-height: 50px;
    $section-title-height: 50px;
    $subheader-height: $section-title-height;
    $footer-height: 50px;

    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    & > aside {
        flex: 0 0 $sidepanel-width;
        align-self: stretch;
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 100%;
        & > article {
            height: 100%;
        }

        article .item {
            .report-schedule {
                display: flex;

                .schedule-tooltip-wrapper {
                    position: relative;
                    height: 20px;
                    margin-top: 2px;
                    margin-bottom: 2px;

                    .schedule-label {
                        color: white;
                        padding: 4px 8px;
                        border-radius: 4px;
                        background-color: #777;
                        font-size: 10px;
                        opacity: 0.4;
                    }

                    .schedule-tooltip {
                        background-color: rgba(0, 0, 0, 0.8);
                        position: absolute;
                        top: 20px;
                        font-size: 10px;
                        line-height: 1.2;
                        z-index: 1;
                        padding: 8px;
                        min-width: 150px;
                        max-width: 288px;
                        border-radius: 4px;
                        cursor: default;

                        .schedule-tooltip-header {
                            font-weight: bold;
                            text-transform: uppercase;
                            color:$blue;
                            letter-spacing: 1px;
                            font-size: 10px;
                            margin-bottom: 6px;
                        }

                        .schedule-tooltip-series:not(:last-child) {
                            margin-bottom: 4px;
                        }

                        .schedule-tooltip-series {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            margin-left: 4px;
                            font-size: 12px;

                            .schedule-tooltip-series-label {
                                cursor: pointer;
                                color: white;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                    }
                }

            }

            &.selected .report-schedule .schedule-label {
                background-color: $blue;
                opacity: 1;
            }
        }
    }

    & > main {
        flex: 1;
        align-self: stretch;
        position: relative;
        overflow: hidden;
        height: 100%;

        & > header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: $header-height;
        }

        & > main {
            position: absolute;
            top: $header-height;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
            padding: 15px;
        }

        & > footer {
            flex: 0 0 $footer-height;
        }
    }

    .section-title,
    .panel-subheader,
    & > main > header {
        border-bottom: $border;
        // line-height: $section-title-height - 1px;
        user-select: none;
        min-height: $section-title-height;
        color: $dark-grey;
        background: $white;
        padding: 10px 16px;

        h1 {
            display: inline-block;
        }
    }

    .view-reporting-list-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;

        .hierarchy-select {
            width: 156px;
            height: 100%;
            line-height: 39px;
            display: flex;
            position: relative;
            opacity: 0.7;
            margin: 0 6px;

            &:hover {
                opacity: 1;
            }

            .select-title {
                font-size: 10px;
                margin-right: 10px;
                display: inline-flex;
                align-items: center;
                text-transform: capitalize;
            }

            .hierarchy-select-dropdown {
                display: flex;
                align-items: center;
                position: relative;

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 2px;
                    top: 17px;
                    z-index: 1000;
                    background-color: $white;
                    height: 16px;
                    line-height: 16px;
                }

                select {
                    width: 94px;
                    height: 28px;
                    background-color: $white;
                    border: 1px solid $border-color;
                    font-size: 12px;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
            }

        }

        .actions {
            flex: 1 1;
            min-width: 500px;

            button {
                position: relative;
            }

            button {
                min-width: 100px;
                height: 31px;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 600;
                letter-spacing: 1px;
                line-height: 29px;
                color: $dark-grey;
                border: 1px solid darken($border-color, 10%);
                border-radius: 2px;

                &:hover {
                    color: $dark-grey;
                }
            }

            button:not(.loading):hover:after {
                background: #111;
                background: rgba(0, 0, 0, 0.8);
                border-radius: 0.2em;
                margin-top: 4px;
                color: #fff;
                display: block;
                left: 1em;
                padding: 0.1em 0.7em;
                line-height: 2em;
                position: absolute;
                font-weight: 400;
                white-space: nowrap;
                z-index: 98;
                font-size: 12px;
                text-transform: none;
            }

            button:not(.loading):hover:before {
                border: solid;
                border-color: #111 transparent;
                border-color: rgba(0, 0, 0, 0.8) transparent;
                border-width: 0em 0.4em 0.4em 0.4em;
                margin-bottom: -4px;
                bottom: 0;
                display: block;
                left: 2em;
                position: absolute;
                z-index: 99;
            }

            .button-delete {
                color: rgba($red, 0.6);
                &:hover {
                    color: $red;
                }
            }

            button.loading {
                pointer-events: none;
                transform: scale(1, 1);
                opacity: 0.9;
                background: transparent url('~images/spinner.gif') no-repeat;
                background-position: center center;
                background-size: 20px;
                transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
                color: rgba(black, 0);
            }
        }
    }

    &.unsaved {
        .view-reporting-list-header {
            .button-save {
                background-color: $yellow;
                border-color: $yellow;
                color: black;
                &:hover {
                    background-color: $light-yellow;
                    color: black;
                }
            }
        }
    }

    &.invalid .view-reporting-list-header .button-run,
    &.invalid .view-reporting-list-header .button-save,
    &:not(.unsaved) .view-reporting-list-header .button-save,
    &:not(.unsaved) .view-reporting-list-header .button-reset {
        border: none !important;
        &:not(.loading) {
            background: rgba(black, 0.02) !important;
        }
        &.loading {
            color: transparent !important;
        }

        &:active {
            box-shadow: none;
            // background: rgba(black, 0.02) !important;
        }
        &:active,
        &:hover {
            cursor: not-allowed !important;
        }
        &:hover:before {
            content: '';
        }
    }
    &:not(.unsaved) .view-reporting-list-header .button-save:not(.loading):hover:after {
        content: 'No changes to save!';
    }
    &:not(.unsaved) .view-reporting-list-header .button-reset:not(.loading):hover:after {
        content: 'No changes to reset!';
    }
    &.invalid .view-reporting-list-header .button-save:hover:after,
    &.invalid .view-reporting-list-header .button-run:hover:after {
        content: attr(hover-msg);
    }

    .section-title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: stretch;

        & > h1 {
            align-self: center;
        }

        & > .actions {
            flex: 1;
            font-size: 12px;
            text-align: right;
            align-self: center;
            button {
                border-radius: 2px;
            }
        }
    }

    & > aside {
        transform: translate3D(0, 0, 0);
        background: $white;
        border-right: $border;

        .list-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: stretch;
            align-items: stretch;
            overflow: hidden;

            .list-action {
                height: $list-action-height;
                min-height: $list-action-height;
                line-height: $list-action-height;
                font-size: 13px;
                color: $dark-grey;
                padding-left: 12px;
                border-bottom: $border;
                cursor: pointer;
                font-weight: 600;
                position: relative;
                margin: 0 10px;
                user-select: none;

                h1 {
                    position: relative;
                    display: inline-block;
                    z-index: 1;
                }

                i {
                    opacity: 0.8;
                    position: relative;
                    left: -5px;
                    &:after {
                        margin: 0px;
                    }
                }

                &:hover {
                    color: $blue;
                }
            }

            .list-action-create {
                font-size: 12px;
                padding: 0px 12px;
            }

            & > ul {
                overflow-x: hidden;
                overflow-y: scroll;

                li {
                    border-bottom: $border;
                    cursor: pointer;
                    padding: 20px 16px;

                    &:hover {
                        h1 {
                            opacity: 1;
                            color: $blue;
                        }
                    }
                    &.selected {
                        background: $white;
                        position: relative;
                        box-shadow: inset 0px 0px 0px 1px $blue;
                        h1 {
                            color: $blue;
                            opacity: 1;
                        }
                    }

                    &.placeholder {
                        opacity: 0;
                    }

                    h1 {
                        display: block;
                        font-size: 12px;
                        font-weight: 600;
                        margin-bottom: 5px;
                        color: $dark-grey;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.25em;
                        height: 1.25em;
                        opacity: 0.8;

                        .ui-match {
                            background: rgba($blue, 0.1);
                        }
                    }

                    h2 {
                        display: block;
                        font-size: 12px;
                        color: $mid-grey;
                        margin-bottom: 0px;
                        white-space: nowrap;
                        line-height: 1.25em;
                        height: 1.25em;
                        text-transform: italic;
                        i {
                            opacity: 0.75;
                        }
                    }

                    p {
                        margin-top: 12px;
                        display: block;
                        font-style: italic;
                        opacity: 0.8;
                        font-size: 12px;
                        line-height: 1.25em;
                        height: 3 * 1.25em;
                        position: relative;
                        overflow: hidden;
                        color: $dark-grey;
                        white-space: pre-wrap;

                        &:after {
                            content: '';
                            text-align: right;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 40%;
                            height: 1.25em;
                            background: linear-gradient(to right, rgba(255, 255, 255, 0), $white 50%);
                        }
                    }
                }
            }
        }
    }
}

.schedule-info-editor {
    .row-schedule-expression {
        label {
            margin-bottom: 0px !important;
        }
    }

    .schedule-expression-editor {
        display: block;
        white-space: nowrap;
        width: 100%;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        &.invalid {
            input {
                border: 1px solid $red;
            }
        }

        input,
        select,
        div {
            height: 40px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-self: center;
            margin-right: 5px;
        }
        input {
            padding: 0.6em 0.8em;
        }
        span {
            font-size: 12px;
            color: $dark-grey;
            align-self: center;
            margin-right: 5px;
        }
        .level-cron {
            margin-right: 0;
            input {
                margin-right: 0;
            }
        }
    }
}

#view-scheduler {
    #view {
        top: 1px;
        right: 0px;
    }
}

.view-reporting {
    .action {
        cursor: pointer;
        display: inline-block;
        &:hover {
            color: $blue;
            text-decoration: underline;
        }
    }

    .schedule-editor,
    .report-editor {
        min-width: 500px;
    }

    .schedule-info-editor,
    .report-info-editor,
    .report-params-editor-group,
    .schedule-target-editor,
    .report-viewer {
        max-width: 700px;
        overflow: hidden;
        width: 100%;
        padding: 25px;
        margin-bottom: 20px;
        border: $border;
        border-bottom-width: 2px;
        background: $white;

        h1 {
            line-height: 1;
            color: $dark-grey;
            font-size: 12px;
            display: inline-block;
            margin-bottom: 1em;
            color: $dark-grey;
            padding-bottom: 2px;
            font-weight: 600;
            margin-top: 0.6em;
        }

        h2 {
            color: $mid-grey;
        }

        .hint,
        .error-message {
            font-size: 12px;
            display: inline-block;
            margin-bottom: 1em;
            padding-bottom: 2px;
        }

        .hint {
            color: $mid-grey;
        }
        .error h1,
        .error-message {
            color: $red;
        }
        .error .hint {
            display: none;
        }
        .error-message {
            display: none;
            font-style: italic;
        }
        .error .error-message {
            display: inline-block;
        }
        .error input,
        .error textarea,
        .error trix-editor {
            border-color: rgba($red, 0.4) !important;
        }

        label {
            position: relative;
            width: 100%;
            line-height: 1;
            color: $dark-grey;

            input {
                display: block;
            }
            &:not(:last-child) {
                margin-bottom: 1em;
                white-space: nowrap;
            }
            & > span:first-child,
            & > .hint {
                font-size: 12px;
                display: inline-block;
                margin-bottom: 1em;
                padding-bottom: 2px;
                color: $dark-grey;
            }
            & > span:first-child {
                font-weight: 600;
            }
            & > .hint {
                color: $mid-grey;
            }
            & > .error-message {
                color: $red;
                font-style: italic;
            }
            &.error,
            &.error > span:first-child,
            &.error > .hint {
                color: $red !important;
            }
            & > p.error-message {
                font-size: 12px;
                margin-bottom: 1em;
            }
            & > input,
            & > select {
                border: $border;
                width: 100%;
                padding: 0.6em 0.8em;
            }
        }

        input,
        select:not(.select-year):not(.select-month):not(.compare-selector) {
            font-size: 12px;
            height: 35px;
            padding: 0.6em 0.8em;
            border: $border;
        }

        .multiline-email-editor {
            textarea {
                min-height: initial;
                height: auto;
            }
        }

        .target-body span {
            margin-bottom: 0px;
        }

        textarea {
            width: 100%;
            resize: none;
            min-height: 70px;
            border: $border;
            color: $dark-grey;
            padding: 0.6em 0.8em;
            font-size: 12px;
        }

        aside,
        .report-params {
            button,
            .button {
                font-size: 10px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                background-color: $white;
                color: $dark-grey;
                border-radius: 1px;
                padding: 0 10px;
                border: 1px solid rgba($mid-grey, 0.3);
                line-height: 25px;
                height: auto;
                border-radius: 2px;

                i {
                    width: auto;
                    margin-right: -6px;
                    position: relative;
                    font-size: 11px;
                    left: -5px;
                    top: 0px;
                    opacity: 0.7;
                }

                &.button-bare {
                    border: none;
                    text-decoration: underline;
                    box-shadow: none;
                    background: none;
                    color: $mid-grey;
                    text-transform: initial;
                    letter-spacing: 0px;
                    font-weight: normal;
                    font-size: 12px;
                    &:hover {
                        color: $blue;
                        background: none;
                    }
                }

                &:hover,
                &:active {
                    color: $blue;
                    background: darken($white, 0.5%);
                    border-color: rgba($mid-grey, 0.4);
                }
            }
        }
    }

    .report-editor,
    .report-viewer,
    .schedule-editor,
    .schedule-target-editor {
        .row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            &.row-description {
                flex: 1;
            }
            & > *:not(:last-child) {
                margin-right: 10px;
            }
        }

        .row-schedule-expression {
            margin-bottom: 1em;
        }

        .report-info,
        .schedule-info {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-between;


            label {
                &.report-description {
                    font-size: 12px;
                    line-height: 1.25em;
                }
                &.report-label {
                    flex: 1;
                    align-self: center;
                }
                &.report-template {
                    align-self: center;
                    .error-message {
                        display: none;
                    }
                    &.error .error-message {
                        display: block;
                    }
                    select {
                        max-width: 300px;
                    }
                }
            }

            & > aside {
                width: 100px;
                margin-left: 20px;

                label {
                    margin-bottom: 0px;
                }

                button {
                    display: block;
                    min-width: 100px;
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                .button-delete {
                    color: rgba($red, 0.5);
                    &:hover {
                        color: $red;
                    }
                }
            }

            & > main {
                flex: 1;

                display: flex;
                flex-flow: column nowrap;
                justify-content: space-around;
                align-items: stretch;
            }
        }
    }

    .schedule-info {
        .row-timezone {
            width: auto;
        }
        .level-cron {
            width: 100%;
        }
        .input-time {
            width: 100px;
        }
        .input-cron {
            font-family: monospace;
            width: 100%;
        }
    }

    .report-info-viewer {
        margin-bottom: 15px;
    }

    .report-params {
        clear: both;

        &:not(.report-params-viewers):not(:last-child) {
            margin-bottom: 20px;
            padding-bottom: 30px;
        }
    }

    .report-viewer .report-params-viewers > *:not(:last-child) {
        margin-bottom: 20px;
        padding-bottom: 30px;
    }

    .report-params-editor-group {
        .report-params:not(:last-child) {
            border-bottom: $border;
        }
    }

    .report-params-viewers {
        .pellet {
            font-size: 11px;
            color: $dark-grey;
            border-radius: 14px;
            display: inline-block;
            border: $border;
            margin-bottom: 8px;
            height: 30px;
            line-height: 28px;
            position: relative;
            padding: 0 12px;
            margin-right: 10px;
            float: left;
            pointer-events: none;
            transition: all 0.15s;
            user-select: none;

            .property {
                text-transform: lowercase;
                font-weight: 400;
                font-style: italic;
            }

            .value {
                text-transform: capitalize;
                font-weight: 600;
            }
        }

        .report-params-metric-select-viewer {
            .label {
                font-weight: 600;
            }
            .metric:not(:last-child):after {
                content: '•';
                margin: 0 3px;
                font-style: normal;
                opacity: 0.5;
            }
        }
    }

    &.invalid .report-params-editor header h1 {
        color: $red;
    }

    .report-params-editor-group {
        border: $border;

        .report-params-column-style {
            .layouts {
                text-align: center;
            }
            .layout {
                min-width: 110px;
                border: $border;
                padding: 20px 10px;
                border-radius: 3px;
                margin: 20px;
                border-collapse: auto;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                user-select: none;

                &:hover {
                    border-color: darken($border-color, 8%);
                    .example {
                        opacity: 1;
                    }
                }
                &.selected {
                    border-color: rgba($blue, 0.7);
                    .label,
                    .description {
                        color: darken($blue, 5%);
                    }
                    .example {
                        opacity: 1;
                    }
                }
                .label {
                    font-size: 12px;
                    font-weight: 600;
                    display: block;
                }
                .description {
                    display: block;
                    font-size: 11px;
                }
                .example {
                    opacity: 0.7;
                    display: inline-table;
                    height: 54px;
                    margin: 12px 0px;
                    .row {
                        display: table-row;
                    }
                    .cell {
                        width: 11px;
                        display: table-cell;
                        background: $light-grey;
                        border: 1px solid $white;
                    }
                }
                $highlight: rgba($blue, 0.8);
                &.layout-compact {
                    .example {
                        $indent: 8px;
                        .cell:first-child {
                            background: $highlight;
                            width: 14px + $indent;
                        }
                        .row:nth-child(1) .cell:first-child,
                        .row:nth-child(4) .cell:first-child {
                            box-shadow: inset -#{math.div($indent, 2)} 0px 0px $white;
                        }
                        .row:nth-child(2) .cell:first-child,
                        .row:nth-child(3) .cell:first-child,
                        .row:nth-child(5) .cell:first-child,
                        .row:nth-child(6) .cell:first-child,
                        .row:nth-child(7) .cell:first-child,
                        .row:nth-child(8) .cell:first-child,
                        .row:nth-child(9) .cell:first-child {
                            box-shadow: inset #{math.div($indent, 2)} 0px 0px $white;
                        }
                    }
                }
                &.layout-tabular {
                    .cell:nth-child(1),
                    .cell:nth-child(2) {
                        width: 14px;
                        background: $highlight;
                    }
                    .row:nth-child(1) .cell:nth-child(2),
                    .row:nth-child(2) .cell:first-child,
                    .row:nth-child(3) .cell:first-child,
                    .row:nth-child(4) .cell:nth-child(2),
                    .row:nth-child(5) .cell:first-child,
                    .row:nth-child(6) .cell:first-child,
                    .row:nth-child(7) .cell:first-child,
                    .row:nth-child(8) .cell:first-child,
                    .row:nth-child(9) .cell:first-child {
                        background: rgba($light-grey, 0.4);
                    }
                }
                &.layout-repeat {
                    .cell:nth-child(1),
                    .cell:nth-child(2) {
                        width: 14px;
                        background: $highlight;
                    }
                    .row:nth-child(1) .cell:nth-child(2),
                    .row:nth-child(4) .cell:nth-child(2) {
                        background: rgba($light-grey, 0.4);
                    }
                }
                &.layout-pivot {
                    .cell:nth-child(1),
                    .cell:nth-child(2) {
                        width: 14px;
                        background: $highlight;
                    }
                }
            }
        }

        .report-params-sort {
            main {
                margin: 10px;
            }
            p {
                font-size: 12px;
                color: $dark-grey;
                font-style: italic;
            }
            .selectors {
                margin-bottom: 10px;
            }
            .button-bare {
                font-size: 11px !important;
                border-left: 3px solid $border-color !important;
            }
            .sort-select {
                .popup {
                    margin-bottom: 30px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
                .property {
                    font-size: 12px;
                    color: $dark-grey;
                    margin-bottom: 0.8em;
                    .index {
                        opacity: 0.8;
                    }
                    .label {
                        font-weight: 600;
                    }
                }
                .selector {
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
                .limit {
                    margin-bottom: 0.8em;
                    position: relative;
                    top: -11px;

                    label {
                        width: auto;
                        display: inline-block;
                    }
                    label span {
                        display: inline-block;
                        line-height: 30px;
                        margin-bottom: 0;
                        margin-right: 2px;
                        color: $mid-grey;
                        font-weight: 400;
                    }
                    input {
                        display: inline-block;
                        height: 30px;
                        width: 75px;
                        border-radius: 3px;
                    }
                }
                .field-select {
                    text-align: left;
                    .first {
                        border-top: 1px solid $border-color;
                    }
                    .group {
                        font-weight: 600;
                        &:after {
                            content: ' -';
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        .report-params-column-properties {
            .pellets {
                margin-top: 10px;
            }

            .pellet {
                font-size: 11px;
                color: $dark-grey;
                border-radius: 14px;
                display: inline-block;
                padding: 0 12px;
                border: $border;
                margin-right: 10px;
                margin-bottom: 8px;
                height: 30px;
                line-height: 28px;
                position: relative;
                user-select: none;

                &.null {
                    margin-right: 24px;
                }

                &:hover {
                    cursor: pointer;
                    &:not(.null) {
                        color: $blue;
                        border-color: rgba($blue, 0.5);
                    }
                    &.null {
                        border: 1px solid darken($border-color, 5%);
                        color: darken($dark-grey, 5%);
                    }
                }

                &.selected {
                    font-weight: 600;
                    &:not(.null) {
                        background: $blue;
                        border-color: $blue;
                        color: $white;
                    }
                    &.null {
                        background: darken($border-color, 10%);
                        border-color: darken($border-color, 10%);
                        color: $white;
                    }
                }
            }
        }

        .report-params-metric-select {
            & > main {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                align-items: stretch;
                height: 400px;
                border: $border;
                border-radius: 4px;
            }

            $header-title-height: 35px;
            $header-info-height: 30px;
            $header-height: $header-title-height + $header-info-height;

            .available,
            .selected {
                flex: 1;
                position: relative;
                &:last-child {
                    border-left: $border;
                }

                & > header {
                    height: $header-height;
                    border-bottom: $border;
                    box-shadow: 0px 1px 1px rgba(black, 0.05);
                    position: relative;
                    z-index: $z-elevated;
                    padding: 0 14px;

                    .row-title {
                        height: $header-title-height;
                        line-height: $header-title-height - 1px;
                        border-bottom: $border;
                        margin: 0 -4px;
                        padding: 0 4px;
                    }

                    .row-info {
                        height: $header-info-height;
                        line-height: $header-info-height - 1px;
                        color: $mid-grey;
                        font-size: 11px;
                        user-select: none;
                        cursor: default;

                        .button-bare {
                            text-decoration: none;
                            padding: 0;
                            height: $header-info-height - 1px;
                            font-size: 11px;
                            text-decoration: underline;
                            position: relative;
                            top: -1px;
                        }
                    }
                }

                & > main {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    top: $header-height;
                    overflow-y: scroll;
                    overflow: auto;
                    background: $body-background-color;
                }
            }

            .selected {
                .row-title {
                    h1 {
                        height: $header-title-height;
                        line-height: $header-title-height - 1px;
                        margin: 0;
                        padding: 0;
                        width: auto;
                    }
                }
            }

            .available {
                .metrics-container {
                    padding-left: 14px !important;
                }
            }

            .available .metric-group {
                padding-right: 35px;
            }
            .selected .metric-group {
                padding-left: 35px;
            }

            .dndDraggingSource {
                display: none !important;
            }

            .dndPlaceholder {
                position: absolute;
                width: 100%;
                display: block;
                height: 1px;
                background-color: rgba($blue, 0.6);
                z-index: $z-elevated;
            }

            .handle {
                position: absolute;
            }

            .metric-group {
                user-select: none;
                display: block;
                position: relative;
                font-size: 11px;
                color: $dark-grey;
                position: relative;
                line-height: 1.6em;
                border-bottom: $border;
                background: $white;

                .metrics-container {
                    padding: 8px 0px;
                    &:hover {
                        .label,
                        .metrics,
                        .move-icon {
                            color: $blue !important;
                        }
                        cursor: pointer;
                        .move-icon {
                            opacity: 0.8;
                        }
                    }
                }

                .move-icon {
                    $height: 34px;
                    height: $height;
                    line-height: $height;
                    position: absolute;
                    top: 50%;
                    margin-top: math.div($height, -2);
                    font-size: 22px;
                    opacity: 0.3;

                    &.move-icon-right {
                        right: 0;
                    }

                    &.move-icon-left {
                        left: 0;
                    }
                }

                .drag-icon {
                    $height: 34px;
                    height: $height;
                    line-height: $height;
                    position: absolute;
                    top: 50%;
                    margin-top: math.div($height, -2);
                    font-size: 22px;
                    right: 0;
                    font-weight: 200;
                    color: rgba($mid-grey, 0.6);

                    &:hover {
                        cursor: move;
                        color: rgba($blue, 0.8);
                    }
                    &:active {
                        cursor: move;
                    }
                }

                .label {
                    display: block;
                    font-weight: 600;
                    font-size: 11px;
                    color: $dark-grey;
                }

                .metrics {
                    display: block;
                    font-size: 11px;
                    color: $dark-grey;

                    &:after {
                        // clearfix
                        content: '';
                        display: table;
                        clear: both;
                    }
                    .metric {
                        float: left;
                        &:not(:last-child):after {
                            content: '•';
                            margin: 0 3px;
                            font-style: normal;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        .report-params-filter {
            & > header {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                align-items: stretch;

                h1 {
                    flex: 0 1 auto;
                    display: inline-block;
                }

                .hint-right {
                    flex: 1;
                    display: inline-block;
                    text-align: right;
                    align-self: baseline;
                }
            }

            .report-params-filter-selected {
                .pellet {
                    font-size: 11px;
                    color: $dark-grey;
                    border-radius: 14px;
                    display: inline-block;
                    padding: 0 12px;
                    border: $border;
                    margin-right: 10px;
                    margin-bottom: 8px;
                    height: 30px;
                    line-height: 28px;
                    position: relative;
                    transition: all 0.15s;

                    i.close {
                        transition: all 0.15s;
                        opacity: 0;
                        font-size: 13px;
                        position: absolute;
                        right: 5px;
                        top: 0;
                        line-height: 28px;
                    }

                    .property {
                        text-transform: lowercase;
                        font-weight: 400;
                        font-style: italic;
                    }

                    .value {
                        text-transform: capitalize;
                        font-weight: 600;
                    }

                    &.selected,
                    &:hover {
                        cursor: pointer;
                        padding-right: 27px;
                        i.close {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .report-params-timerange {
            clear: both;
            overflow: hidden;

            & > header {
                display: block;
            }

            .report-params-timerange-options-container {
                margin-top: 15px;

                .report-params-timerange-options-toggle {
                    font-size: 11px;
                    opacity: 0.8;
                    user-select: none;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }

                &.active .report-params-timerange-options-toggle {
                    color: $blue;
                    opacity: 1;
                }

                .report-params-timerange-options {
                    font-size: 10px;
                    user-select: none;

                    .report-params-timerange-option {
                        margin-top: 15px;
                        margin-left: 6px;
                        padding-left: 15px;
                        border-left: 2px solid $border-color;
                        span {
                            font-size: 11px;
                            line-height: 15px;
                        }
                        .option-title {
                            font-weight: 600;
                        }
                        .option-help-text {
                            opacity: 0.8;
                            &:before {
                                content: ' | ';
                            }
                        }
                    }
                    .report-params-timerange-option-mode {
                        select {
                            display: block;
                            font-size: 10px;
                            line-height: auto;
                            height: 30px;
                            width: auto;
                            padding: auto;
                        }
                    }
                }
            }

            .report-params-timerange-select-x-from-now {
                margin-top: 20px;
                font-size: 12px;

                .start-date {
                    display: block;
                }
                select,
                input,
                span {
                    display: inline-block;
                    margin: 0 2px;
                }
                select,
                input {
                    height: auto;
                    padding: 1px 6px;
                }

                select {
                    position: relative;
                    top: -1px;
                }

                .input-offset-count-container {
                    span {
                        font-size: 12px;
                        margin-right: 10px;
                        opacity: 0.7;
                    }

                    .input-offset-count {
                        width: 65px;
                        height: 30px;
                        font-size: 13px;
                        margin-top: 10px;
                    }
                }

                .display {
                    margin-top: 30px;
                    h1 {
                        display: block;
                        margin-top: 0;
                        margin-bottom: 6px;
                    }
                    h1,
                    .hint {
                        font-size: 12px;
                        font-weight: 400;
                    }
                    .display-content {
                        border-left: 3px solid rgba($light-grey, 0.2);
                        padding-left: 10px;
                        padding-top: 5px;
                        padding-bottom: 8px;
                    }
                    .display-timerange {
                        font-size: 13px;
                    }
                    .display-error {
                        font-style: italic;
                    }
                    .start,
                    .end {
                        font-weight: 600;
                    }
                }
            }

            .custom-timerange {
                display: inline-block;

                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: $z-modal - 1;
                    background: rgba($navy, 0.8);
                }
                .date-select-container {
                    position: fixed;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 844px;
                    z-index: $z-modal;

                    // remove arrow
                    &:after {
                        display: none;
                    }
                }

                .smart-groups-filter-time > button {
                    .filter-display-date-container,
                    span {
                        width: 100%;
                        text-transform: capitalize;

                        .icon-calendar {
                            width: 16px;
                            text-align: center;
                            color: $dark-grey;
                            position: relative;
                            top: 0;
                            margin-right: 3px;
                            left: 0;
                            font-size: 12px;
                        }
                    }
                    margin-left: 10px;
                    min-width: 200px;
                    height: 35px !important;
                    border-radius: 3px;
                }

                .buttons {
                    button {
                        height: 49px;
                        border: none;
                        border-radius: 0px;
                        font-size: 13px;
                    }
                    .button-save {
                        background: $yellow;
                        height: 50px;
                        color: $navy !important;
                    }
                }
            }
        }

        .report-params-currency {
            select {
                min-width: 200px;
            }
        }

        .report-params-hierarchy {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-around;
            align-items: stretch;
            user-select: none;

            .report-params-hierarchy-list {
                $list-item-height: 30px;
                flex: 1 1 50%;
                position: relative;
                padding: 0 5px;

                display: flex;
                flex-flow: column nowrap;
                justify-content: space-around;
                align-items: stretch;

                margin-bottom: 10px;
                margin-top: 10px;

                &.report-params-hierarchy-list-available {
                    .button-close {
                        display: none;
                    }
                }

                &.report-params-hierarchy-list-selected {
                    .dndDragging .button-close {
                        display: none;
                    }

                    .button-close {
                        $size: 11px;
                        $padding: 2px;
                        position: relative;
                        display: inline-block;
                        width: $size + $padding;
                        height: $size + $padding;
                        position: relative;
                        top: 3px;
                        left: 5px;
                        cursor: pointer;
                        transition: all 0.1s;

                        i {
                            transition: opacity 0.1s;
                            line-height: ($size + $padding);
                            font-size: ($size + $padding);
                            margin: 0;
                            padding: 0;
                            position: absolute;
                            left: -3px;
                            right: 0;
                            bottom: 0;
                            top: 0;
                        }

                        .icon-cancel {
                            color: $mid-grey;
                        }

                        .icon-cancel-circled {
                            opacity: 0;
                            color: rgba($red, 0.8);
                        }

                        &:hover {
                            .icon-cancel {
                                opacity: 0;
                            }
                            .icon-cancel-circled {
                                opacity: 1;
                            }
                        }
                    }
                }

                & > header {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: stretch;

                    font-size: 11px;
                    margin-bottom: 0.4em;
                    padding-bottom: 0.4em;

                    h1 {
                        flex: 0 1 auto;
                        display: inline-block;
                        margin: 0px;
                        padding: 0px;
                        margin-right: 10px;
                        font-weight: 400;
                    }

                    .hint-right {
                        flex: 1;
                        display: inline-block;
                        text-align: right;
                        align-self: baseline;
                    }

                    button {
                        height: auto;
                        line-height: 0.9em;
                        padding: 0px;
                        text-decoration: none;
                    }
                }

                main {
                    position: relative;
                    min-height: $list-item-height + 22px;
                    margin: 0 5px;
                    flex: 1;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: stretch;
                }

                ul {
                    flex: 1;
                    display: block;
                    padding: 5px;
                    min-height: $list-item-height + 22px;

                    li {
                        cursor: pointer;
                        font-size: 11px;
                        padding: 0px 12px;
                        border: $border;
                        float: left;
                        line-height: $list-item-height - 4px;
                        position: relative;
                        margin: 5px;
                        background: $white;
                        border-radius: 50px;
                        color: rgba($dark-grey, 0.9);
                        transition: all 0.1s;

                        &:hover {
                            border-color: rgba($blue, 0.5);
                            color: $blue;
                        }

                        &.dndPlaceholder {
                            padding: 0;
                            border: none;
                            position: relative;
                            margin: 0px;
                            min-height: $list-item-height;
                            min-width: 2px;
                            max-width: 2px;
                            background: $blue;
                            position: relative;
                            top: 5px;
                        }
                        &.selected {
                            background-color: #dff0d8;
                            color: #3c763d;
                        }
                        &.dndDraggingSource {
                            display: none;
                        }
                        .property {
                            display: table-cell;
                            vertical-align: middle;
                            min-height: $list-item-height;
                        }
                    }
                }
            }
        }
    }

    .schedules-list {

        .schedule-label {
            font-size: 14px;
        }
        .schedule-expression-label {
            font-size: 12px;
            color: $mid-grey;
            display: block;
            line-height: 1.2;
            font-style: italic;
        }
        .schedule-report-label {
            font-size: 12px;
            color: $mid-grey;
            display: block;
            line-height: 1.2;
            margin-bottom: 7px;
            padding-left: 7px;
        }
        h2 {
            display: block;
            font-weight: 600;
            margin-top: 7px;
            margin-bottom: 6px !important;
            font-size: 12px;
        }
        .schedule-report-labels {
            margin-bottom: 10px;
        }
        .schedule .schedule-report-error {
            color: rgba($red, 0.7);
            font-style: italic;
            display: none;
        }
        .schedule .error .schedule-report-error {
            display: block;
        }
    }

    &.view-reporting-schedules {
        .report-viewer {
            margin-bottom: 0px;
        }

        .report-templates {
            margin-bottom: 20px;
            .report-template:not(:last-child) {
                margin-bottom: 20px;
            }
            .report-templates-actions {
                margin-top: 5px;
                text-align: right;
                max-width: 700px;
                button {
                    user-select: none;
                    color: rgba($dark-grey, 0.7);
                    border-radius: 5px;
                    background: rgba($white, 0.8);
                    &:hover {
                        color: rgba($dark-grey, 1);
                        background: $white;
                    }
                    i {
                        font-size: 13px;
                        width: auto;
                        padding-right: 4px;
                        opacity: 0.6;
                    }
                    span {
                        font-size: 12px;
                    }
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    height: auto;
                    padding: 5px 7px;
                    width: 100%;
                }
            }
        }
    }

    .search-container,
    .list-action-search {
        display: flex;
        flex-flow: row-reverse nowrap;
        justify-content: space-around;
        align-items: stretch;
        width: 100%;
        margin-left: -4px;

        &.filtered {
            .icon-clear-filter {
                display: inline-block;
            }
            .icon-search {
                color: $blue;
            }
            input {
                color: $blue;
            }
        }

        &:hover .icon-search,
        input:focus ~ .icon-search {
            opacity: 0.8;
        }

        &:hover input,
        input:focus {
            @include placeholder {
                color: rgba($dark-grey, 0.8);
            }
        }

        i {
            display: inline-block;
            text-align: center;
            flex: 0;
            color: $mid-grey;
            opacity: 0.5;
        }
        .icon-search {
            padding-right: 6px;
        }
        .icon-clear-filter {
            display: none;
            padding-left: 6px;
            cursor: pointer;
            font-size: 14px;
            margin-right: -8px;
            &:hover {
                opacity: 0.8;
            }
        }

        input {
            flex: 1;
            display: block;
            border: none;
            padding: 0;
            height: auto;
            // line-height: $header-title-height - 1px;
        }
    }

    .list-action-search {
        padding-left: 16px;
        padding-right: 0px;
        height: 35px;
        width: auto;
    }

    .list-container-reports {
        .models {
            height: 100%;
        }

        ul {
            &.has-templates {
                top: $list-action-height * 3;
            }
        }
    }

    .list-container-schedules ul {
        flex: 1 1 50px;
    }

    .list-container-report-templates ul {
        flex: auto;
    }
}
