
.controls-pagination {
    $height: 40px;
    $input-width: 100px;

    .details {
        text-align: left;
        line-height: $height;
        font-size: 10px;
        padding: 0 20px;

        em {
            font-weight: 600;
        }
    }

    input, button {
        border: none;
        height: 100%;
        color: inherit;

        @include placeholder {
            color: #ccc !important;
        }
    }

    input {
        font-size: 18px;
        padding: 0 0.5em;
        background: none;
    }

    label {
        font-size: 18px;
    }

    fieldset {
        $button-width: $height;
        $input-width:  $height * 1.5;
        width: ($button-width * 2) + $input-width + 1px;
        height: $height;
        border: none;
        border-right: $border;
        display: inline-block;

        input[type=number]::-webkit-inner-spin-button ,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        button, input {
            float: left;
        }

        .page-next {
            right: 0;
        }

        .page-prev {
            left: 0;
        }

        input {
            width: $input-width;
            right: $button-width;
            border-left: $border;
            border-right: $border;
            text-align: center;
        }

        button {
            width: $button-width;
            // width: 50px;
            border-radius: none;
            background: none;
            font-size: 18px;

            &:hover {
                cursor: pointer;
            }

            &:active {
            }
        }
    }
}