@use 'sass:math';

$header-background-color: $navy;
$header-active-background-color: #2f3642;
$header-font-color: rgb(92, 100, 104);
$header-border-color: darken($header-background-color, 10%);
$header-active-color: $yellow;
$header-active-color-reporting: $purple;

$organization-logo-size: math.div($topbar-height, 2);

$logo-width: $topbar-height;
$logo-height: $topbar-height;

.reporting {
    .logo {
        background-color: $header-active-color-reporting !important;
    }
    nav > ul li {
        .active {
            color: $header-active-color-reporting !important;
        }
        &:hover {
            color: $header-active-color-reporting !important;
        }
    }
}

// used for elements that display only while loading
.fake {
    height: $topbar-height;
    background: $header-background-color;
    position: relative;

    .support {
        position: absolute;
        right: $smart-group-panel-width;
        padding-right: 17px;
        padding-left: 10px;
        line-height: $topbar-height;
        vertical-align: top;
        width: auto;
        color: #fdfdfd;
        display: inline-block;
        font-size: 10px;
        text-transform: uppercase;
        float: right;

        i {
            padding-right: 7px;
        }

        &:hover {
            color: $header-active-color;
            background-color: $header-active-background-color;
        }
    }
}

#app > header {
    font-weight: 600;
    letter-spacing: 1px;
    overflow: hidden;

    .top-bar,
    .side-bar {
        background-color: $white;
        z-index: $z-header;
    }

    .logo {
        $height: $topbar-height * 0.6;
        height: $logo-height;
        width: $logo-width;
        background-color: $yellow;
        left: 0;
        top: 0;
        background-image: url('~images/42logo-black.png');
        background-size: $topbar-height * 0.45;
        background-repeat: none;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        transition: background-color 0.2s;
    }

    .collapses {
        display: flex;
        flex-direction: row;
        max-height: $topbar-height;
        overflow: hidden;

        &.small {
            flex-direction: column;
            max-height: none;
        }

        &.hide {
            display: none;
        }
    }

    .top-bar {
        position: absolute;
        top: 0;
        left: 0;
        line-height: $topbar-height;
        background: $header-background-color;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fdfdfd;
        width: 100%;
        user-select: none;
        overflow: hidden;

        .collapse-header {
            height: $topbar-height;

            .header-logo {
                height: 40px;
                width: 40px;
            }

            .icon-menu {
                vertical-align: top;
                text-align: center;
                height: $topbar-height;
                display: inline-block;
                font-size: 20px;
                opacity: 0.3;
                position: absolute;
                top: 0px;
                left: $topbar-height;
                right: $smart-group-panel-width;
                text-align: left;
                padding-left: 6px;
                &.active {
                    background: lighten($navy, 10%);
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .right {
            top: 0;
            margin-left: auto;
            margin-right: $smart-group-panel-width;

            @media (max-width: $navbar-collapse-breakpoint) {
                margin-right: auto;
                width: 100%;
            }

            & > * {
                transition: all 0.2s;
                text-align: center;
                float: right;
                font-size: 10px;
                letter-spacing: 1px;
                &:hover {
                    background: lighten($header-background-color, 5%) !important;
                    color: $header-active-color;
                }
                a {
                    display: inline-block;
                    padding-left: 10px;
                    padding-right: 17px;
                    i {
                        padding-right: 7px;
                    }
                }
            }
        }

        .organization {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: $smart-group-panel-width;
            border-left: 1px solid rgba(white, 0.05);
            cursor: default;

            .organization-logo {
                position: absolute;
                left: 20px;
                top: math.div(($topbar-height - $organization-logo-size), 2);
                width: $organization-logo-size;
                height: $organization-logo-size;
                display: inline-block;
                border-radius: 100%;
                background: #444;
            }

            .organization-label {
                color: inherit;
                text-transform: uppercase;
                position: absolute;
                left: 20px + $organization-logo-size + 10px;
                white-space: pre;
                right: 0;
                line-height: $topbar-height;
                font-size: 10px;
                letter-spacing: 2px;
            }
        }

        & > ul {
            left: $logo-width;

            font-size: 0;

            & .page-select {
                flex-grow: 1;
                background: $header-background-color;

                li.active {
                    background: lighten($header-background-color, 5%) !important;
                    color: $header-active-color;
                }
            }

            li {
                transition: all 0.2s;

                display: inline-block;
                text-align: center;

                font-size: 10px;
                letter-spacing: 1px;

                &:hover {
                    background: lighten($header-background-color, 5%) !important;
                    color: $header-active-color;
                }

                a {
                    display: block;
                    padding: 0 20px;
                    overflow: hidden;
                    max-height: $topbar-height;

                    i {
                        padding-right: 7px;
                    }
                }
            }
        }
    }
}
