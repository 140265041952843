@use 'sass:math';

.supertable {
    $header-height: 35px;
    $footer-height: 30px;
    $row-height: 35px;
    $header-row-height: 30px;

    $column-index-width:    50px;
    $column-score-width:    100px;
    $column-property-width: 150px;

    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;

    border-bottom: $border-color;
    overflow: hidden;
    height: auto;
    $side-padding: 15px;

    table {
        font-size: 12px;
        height: auto;
        width: 100%;
        table-layout: fixed;
    }

    tr {
        td, th {
            &:first-child { border-left: none; }
            &:last-child  { border-right: none; }

            vertical-align: middle;
            padding: 0px $side-padding;
            position: relative;
            box-shadow: inset 0px 1px rgba(white, 0.8);
        }
    }

    & > main {
        transform: translateZ(0);

        position: absolute;
        bottom: $footer-height;
        right: 0;
        left: 0;
        top: $header-height;
        overflow-x: hidden;
        overflow-y: scroll;
        background: $white;
        z-index: $z-hidden;

        td {
            background: $white;
            color: $darker-grey;
            height: $row-height;
            border: 1px solid rgba($table-body-border-color, 0.4);
            border-bottom: $table-body-border;
            line-height: $row-height;
        }

        tr:hover td {
            background-color: rgba($border-color, 0.2);
            color: $blue;
        }
    }

    & > header {
        box-shadow: inset 0px 1px white;
        height: $header-height;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: $z-base;
        border-bottom: $table-head-border;
        background: $white;
        box-shadow: 0px 1px 0px rgba($table-body-border-color, 0.4);
        backface-visibility: hidden;

        tr {
            height: $header-row-height;
        }

        th {
            font-weight: 700;
            letter-spacing: 1px;
            height:      $header-row-height;
            max-height:  $header-row-height;
            line-height: $header-height;
            font-size:   $header-height * 0.25;
            border-right: $table-head-border;

            .ui-select {
                width: 100%;
                height: 100%;
            }
        }

        tr:first-child:not(:only-child) {
            border-bottom: $table-head-border;
        }

        .row-search th {
            padding: 0;
            border-top: 1px solid $body-background-color;

            $search-width: 32px;

            &:hover .icon-search {
                color: $mid-grey;
            }

            .search-container {
                position: relative;
                width: 100%;
                height: $header-height - 1;

            }

            .icon-search {
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: $header-height;
                text-align: center;
                margin-left: 13px;
                font-size: 13px;
                color: $light-grey;
                pointer-events: none;
                background: none;

                &:before {
                    position: relative;
                    top: 1px;
                }
            }

            input {
                position: absolute;
                top: 0; left: 0; right: 0; bottom: 0;
                width: 100%;

                border: none;
                border-radius: none;

                margin: 0;
                padding: 0;
                padding-left: $search-width;
                padding-right: math.div($side-padding, 2) + 1;
            }
        }
    }

    .row-histogram {
        width: 100%;
        text-align: center;
        height: $row-height - 10px;

        .bar {
            background: $body-background-color;
            display: inline-block;
            position: relative;
            width: 6px;
            height: 100%;
            margin: 0 3px;
            border-radius: 1px;
            overflow: hidden;
        }

        .bar-value {
            position: absolute;
            left: 0; right: 0; bottom: 0;
            background: hsl(265deg, 100%, 85%);
        }
    }

    tr:hover .row-histogram .bar-value {
        background: rgba($blue, 0.5) !important;
    }

    & > footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: $footer-height;
        z-index: $z-base;
        border-top: $border;
        background: $white;
        box-shadow: inset 0px 1px white;

        td {
            height: $footer-height;
            border: $table-head-border;
        }

        tr:first-child td {
            border-top: none;
        }
    }

    & > header, & > footer {
        td, th {
            color: $dark-grey;
        }
    }

    .light-column {
        white-space: nowrap;
        font-size: 10px;
        color: $mid-grey;
        padding: 0;
        border-right: none !important;
    }

    .small-column {
        width: 60px;
        text-align: left !important;
        padding-left: 10px !important;
    }

    .small-column + td {
        border-left: none;
    }

    .column-index {
        width:     $header-row-height + 10px;
        min-width: $header-row-height + 10px;
        max-width: $header-row-height + 10px;
        text-align: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    & > main {

        .column-property .total-label {
            float: right;
        }

        td.column-sales, td.column-volume, td.column-margin {
            text-align: right;
        }

        td.column-middle {
            text-align: center;
            width: 80px;
            margin: auto;
            border-right: none;
        }
    }

    .total-label {
        float: left;
    }

    .total-number {
        float: right;
        font-weight: bold;
    }

    .weight {
        float: right;
        font-weight: 300;
        display: none !important ;
    }

    td.column-index, td.column-score {
        color: $mid-grey;
    }

    tr:hover {
        td.column-index, td.column-score {
            color: $blue;
        }
    }

    td:not(.column-index):not(.column-property) {
        text-align: right;
    }

    .sortable.column-index {
        &:after {
            display: block;
            text-align: center;
            float: none;
            width: 100%;
            position: relative;
            left: 1px;
        }
    }

    .column-property {
        position: relative;
    }

    /*&.has-second-property {
        .column-property {
            width:     $column-property-width - 60px;
            min-width: $column-property-width - 60px;
            max-width: $column-property-width - 60px;
        }

        footer {
            .column-property {
                $width: ($column-property-width - 60px) * 2;
                width:     $width;
                min-width: $width;
                max-width: $width;
            }
        }
    } */

    .top-value td:first-child {
        font-weight: 700;
        color: $light-blue;
    }

    &:not(.has-second-property) {
        .column-property {
            width:     $column-property-width;
            min-width: $column-property-width;
            max-width: $column-property-width;
        }
    }

    &:not(.has-margin):not(.has-second-property) {
        .column-property {
            width:     $column-property-width + 80px;
            min-width: $column-property-width + 80px;
            max-width: $column-property-width + 80px;
        }
    }

    &.has-margin:not(.has-second-property) {
        .column-property {
            width:     $column-property-width + 30px;
            min-width: $column-property-width + 30px;
            max-width: $column-property-width + 30px;
        }
    }

    td.column-property {
        vertical-align: middle;
    }

    .column-property-value {
        @include text-overflow-ellipsis();
        display: block;
        line-height: 1.5;
        position: relative;
        top: 1px;
    }

    .column-property-value-name {
        @include text-overflow-ellipsis();
        display: block;
        position: relative;
        top: 2px;
        line-height: 1.5;
        font-size: 10px;
        opacity: 0.6;
    }

    .column-score {
        width:     $column-score-width;
        min-width: $column-score-width;
        max-width: $column-score-width;
    }

    .column-growth {
        width:     110px;
        min-width: 110px;
        max-width: 110px;
    }

    td.column-growth {
        text-align: left !important;
    }

    .column-group-volume {
        tr td:first-child, tr th:first-child {
            border-left: 1px solid rgba($blue, 0.4) !important;
        }
    }

    .column-group-margin {
        tr td:first-child, tr th:first-child {
            border-left: 1px solid $yellow !important;
        }
    }
}
